
import React,{useState,useEffect} from "react";
import NewsSkeleton from "../../Home/Skeleton";
import NCCard from "./NCCard";
import Header from "../Header";
import API from "../../../API/API";
import { useDispatch,useSelector } from "react-redux";


const NewsController =()=>{

    const [isLoading, setLoading] = useState(true);
    const [news,setNews] = useState({});
    const visitorId = useSelector((data)=>data.appState.visitorId)
    const token = useSelector((data)=>data.appState.token)
    const dispatch = useDispatch()
    const FetchProducts = async () =>
    {
     
      const requestOptions = {
        method: 'POST',
        Accept:'application/json',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization':token,
         },
        body: JSON.stringify({"visitorId":`${visitorId}`})
      };
      
      try {
        const response = await fetch(`${API.BASE_URL}/admin/readnews`,requestOptions)
        const json = await response.json();
        var data = json
        setNews(data);   
     
      } 
      catch (error) 
      {
        console.error(error);
      }
       finally 
       {
           setLoading(false)
          
      } 
     }
  
      useEffect(() =>{
      FetchProducts()
       
    },[]);

    const NewsList = ({data})=>{
       
        return (
            <div>
            {
               data.map((data)=><NCCard  heading = {data.heading} image={data.image} body={data.body} comments={data.comments} likes={data.likes} views={data.views} share={data.share}  bookmarks={data.bookmarks} date={data.date}  data={data} />)

            }
            </div>
        )
    }
  
    return (
      <div>
         <Header />
          {isLoading?<NewsSkeleton isLoading={isLoading} />:<NewsList  data = {news.data} />}
      </div>
    );
  }

  export default NewsController;