import BrokersAppBar from "../../components/nav-bar/navbar";
import BrokerSideBar from '../../components/sidebar/sidebar';
import { useSelector } from 'react-redux';
import News from "./News";

const  Sports = () => {

  const appTheme = useSelector((data)=>data.appState.appTheme)

  return (
    <div style={{backgroundColor:`${appTheme}`}}>
        <BrokersAppBar  menuOption="Sports" />
        <br />
        <br />
        <div style={{marginTop:20}}>
        <br />
        <News />
        <br />
        <BrokerSideBar />
        </div>
      
    </div>
  );
}

export default Sports;