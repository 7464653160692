import * as React from 'react';
import { Routes, Route} from 'react-router-dom';
import Home from './screens/Home/Home';
import CreateAccount from './screens/Account/CreateAccount/CreateAccount';
import SuccessPage from './screens/Account/SuccessPage';
import Login from './screens/Account/Login/Login';
import AdminPanel from './screens/AdminPanel/AdminPanel';
import CreateNews from './screens/AdminPanel/CreateNews/CreateNews';
import NewsDetails from './screens/Home/News/NewsDetails.js/NewsDetails';
import NewsController from './screens/AdminPanel/News/NewsController';
import Profile from './screens/Account/Profile/Profile';
import NewsDetailsUsepParams from './screens/Home/News/NewsDetails.js/NewsDetailsUseParam';
import ForgotPassword from './screens/Account/Login/ForgotPassword';
import Otp from './screens/Account/Login/Otp';
import ResetPassword from './screens/Account/Login/resetPassword';
import Tech from './screens/Tech/Tech';
import Sports from './screens/Sports/Sports';
import Jobs from './screens/Jobs/Jobs';
import Crypto from './screens/Crypto/Crypto';
import ActionSuccessPage from './screens/AdminPanel/News/actionSucces';
import VisitorController from './screens/AdminPanel/Visitors/VisitorController';
import NotFound from './screens/NotFound/NotFound';
import About from './screens/About/About';
const  App = () => {

  return (
    <Routes> 
        <Route path="*" element={<Home />} />
        <Route path="/home" index element={<Home />} />
        <Route path="/tech"  element={<Tech />} />
        <Route path="/sports"  element={<Sports />} />
        <Route path="/jobs"  element={<Jobs />} />
        <Route path="/crypto"  element={<Crypto />} />
        <Route path="/createaccount"  element={<CreateAccount />} />
        <Route path="/login"  element={<Login />} />
        <Route path="/successpage" element={<SuccessPage />} />
        <Route path="/PIDControllerX"  element={<AdminPanel />} />
        <Route path="/PIDControllerX/createnews"  element={<CreateNews />} />
        <Route path="/readnews"  element={<NewsDetails />} />
        <Route path="/readnews/:id"  element={<NewsDetailsUsepParams />} />
        <Route path="/PIDControllerX/adminnews"  element={<NewsController />} />
        <Route path="/profile"  element={<Profile />} />
        <Route path="/forgotpassword"  element={<ForgotPassword />} />
        <Route path="/otp/page"  element={<Otp />} />
        <Route path="/resetpassword"  element={<ResetPassword />} />
        <Route path="/actionsuccesspage"  element={<ActionSuccessPage />} />
        <Route path="/PIDControllerX/visitorcontroller"  element={<VisitorController />} />
        <Route path="/about"  element={<About />} />
        <Route path="/notfound"  element={<NotFound />} />
    </Routes>
  );
}

export default App;
