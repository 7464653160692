import React,{useState,useEffect,useRef} from "react"
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useNavigate } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import logo from "../../../assets/logo/logo.png";
import { useSelector} from 'react-redux';
import API from "../../../API/API";

const  Otp = () => {

   
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const WindowWith = windowSize.current[0]
  const navigate = useNavigate();
  const [otp,setOtp] = useState("")
  const [isLoading, setLoading] = useState(false);
  const [mismatch,setMismatch] = useState(0)
  const [validation,setValidation]= useState(false)
  const [emailExisted,setEmailExisted] = useState(0)
  const [invalidEmail,setInvalideEmail] = useState(0)
  const [state,setState] = useState([]);
  const userEmail = useSelector((data)=>data.appState.email)
  const confirmOTP = async () =>
  {
 
    if(otp===""){
        setValidation(false);
        setInvalideEmail(1);
    }else {
        setInvalideEmail(0);
        setValidation(true);
    }

   

   if(validation===true)
  {
      setLoading(true)
    const requestOptions = {
      method: 'POST',
      Accept:'application/json',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({"email":`${userEmail}`,"otp":`${otp}`})
    };
    
    try {
    
      const response = await fetch(`${API.BASE_URL}/confirmotp`,requestOptions)
      const json = await response.json();
      var data = json
      setState(data);   
   
    } 
    catch (error) 
    {
      console.error(error);
    }
     finally 
     {
         setLoading(false)
        
    } 
   }
}

    useEffect(()=>{
        if(state.status===201){
           navigate("/resetpassword") 
            }else if(state.status===401){//Incorrect password
              setLoading(false)
              setEmailExisted(1)
             
            }
        
  },[state]);





  return (
    <div style={{flex:1,height:"800px"}}>
         <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
              <img onClick={()=>navigate("/home")}  src={logo} style={{marginTop:"55px",marginBottom:3}} height={130} width={130} alt='logo'/>
              <p  style={{marginLeft:WindowWith*0.0,marginBottom:-10,fontSize:10,color:"black"}}>Please enter OTP sent to this email</p>
      <p style={{ marginLeft:WindowWith*0.026,marginBottom:-10,fontSize:10,color:"blue"}}>({userEmail})</p>
         </div>
     <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <div>

        <TextField
          label="enter your OTP................."
          id="outlined-start-adornment"
          sx={{ mt:3,ml:WindowWith * 0.025, width:WindowWith * 0.6 }}
          onChange={(event)=>setOtp(event.target.value)}
          required={true}
        />
      
      <p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:-5,marginBottom:-5,marginLeft:100}}>{invalidEmail===1?<p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:10,marginBottom:-10,marginLeft:0}}>Please enter a valid OTP</p>:""}</p>
      <p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:-5,marginBottom:-5,marginLeft:WindowWith*0.45}}>{emailExisted===1?<p style={{color:"red",fontWeight:"bold",fontSize:10,marginTop:10,marginBottom:-10,marginLeft:-(WindowWith*0.2)}}>{state.message}</p>:""}</p>
       
            <p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:-5,marginBottom:-5,marginLeft:100}}>{mismatch===1?<p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:10,marginBottom:-10,marginLeft:0}}>{state.message}</p>:""}</p>
           <LoadingButton
          size="small"
          onClick={()=>confirmOTP()}
          loading={isLoading}
          loadingIndicator="Loading…"
          variant="outlined"
          sx={{ mt:3,ml:WindowWith * 0.025, width: WindowWith * 0.6 ,p:2,borderColor:"black",color:"black"}} 
        >
          <span>CONFIRM OTP</span>
        </LoadingButton>

      </div>
    </Box>

    </div>
  );
}

export default Otp;