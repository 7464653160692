import React,{useState,useEffect,useRef} from "react"
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import InsertCommentOutlinedIcon from '@mui/icons-material/InsertCommentOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import TurnedInNotOutlinedIcon from '@mui/icons-material/TurnedInOutlined';
import { useSelector,useDispatch } from 'react-redux';
import logo from "../../assets/logo/logo.png";
import logob from "../../assets/logo/logob.png";
import { SetProductID } from "../../Redux/Reducers/appState";
import ShareIcon from '@mui/icons-material/Share';
import { setShareModal,setShareLink } from "../../Redux/Reducers/appState";
import API from "../../API/API";
import { Divider } from "../../components/Controllers/NumberFormater";
import FavoriteIcon from '@mui/icons-material/Favorite';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { Bookmark } from "@mui/icons-material";


export const NewsCard =({heading,body,image,date,likes,views,comments,bookmarks,share,data})=> {

  const navigate = useNavigate();
  const appTheme = useSelector((data)=>data.appState.appTheme)
  const appTextColor = useSelector((data)=>data.appState.appTextColor)
  const user_id = useSelector((data)=>data.appState.userID)
  const post_id = data.id
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const WindowWith = windowSize.current[0]
  const dispatch = useDispatch();

  const [likeClick,setLikeClick] = useState(false)
  const [like,setLike] = useState(0)

  const [bookmarkClick,setBookMarkClick] = useState(false)
  const [bookmark,setBookMark] = useState(0)


const link = heading.replaceAll(" ","+");


const likePost = async () =>{
     
     setLikeClick(true)
     setLike(1)

    const requestOptions = {
    method: 'POST',
    Accept:'application/json',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({"post_id":`${post_id}`,"user_id":`${user_id}`})
    };

      try{
        const response = await fetch(`${API.BASE_URL}/readnews/likepost`,requestOptions)
      }catch(error){

        alert(error);

      }finally{


      }
}

const bookmarkPost = async () =>{
     
  setBookMarkClick(true)
  setBookMark(1)

 const requestOptions = {
 method: 'POST',
 Accept:'application/json',
 headers: { 'Content-Type': 'application/json' },
 body: JSON.stringify({"post_id":`${post_id}`,"user_id":`${user_id}`})
 };

   try{
     const response = await fetch(`${API.BASE_URL}/readnews/bookmarkpost`,requestOptions)
   }catch(error){

     alert(error);

   }finally{


   }
}



  return (
    <div style={{ maxWidth: 345 ,backgroundColor:`${appTheme}`, margin:2,borderStyle:"solid",borderWidth:1,borderRadius:5,borderColor:"#E4E4E4",marginBottom:20}} >
      <div onClick={()=>{dispatch(SetProductID(link));navigate("/readnews")}}>
      <div style={{display:"flex",flexDirection:"row",margin:12}}>
       {appTheme==='white'?<img src={logo} style={{marginLeft:"0px",marginBottom:3}} height={WindowWith * 0.09} width={WindowWith * 0.09} alt='logo'/>:<img src={logob} style={{marginLeft:"0px",marginBottom:3}} height={WindowWith * 0.09} width={WindowWith * 0.09} alt='logo'/> }
       <p style={{color:`${appTextColor}`,marginLeft:WindowWith * 0.03,marginTop:2,fontWeight:"500",fontSize:14}}>{heading}</p>
       </div>
       <p style={{color:`${appTextColor}`,marginTop:-20,fontWeight:"200",fontSize:10,marginLeft:WindowWith * 0.43}}>{date}</p>
      <CardMedia
        component="img"
        height="300"
        image={image}
        alt="P-Blog"
      />

      <div style={{display:'flex',flexDirection:'row',marginLeft:"20px"}}>
        <p style={{color:`${appTextColor}`}}>
            {body.substr(0, 45)+"...Read more"}
        </p>
       
      </div>
      </div>
      <IconButton onClick={()=>{dispatch(SetProductID(data.id));navigate("/readnews")}} sx={{marginLeft:3}}  aria-label="add to favorites">
          <InsertCommentOutlinedIcon sx={{color:`${appTextColor}`}}  />
          <p style={{marginLeft:1,fontSize:13,color:`${appTextColor}`}}>{Divider(comments)}</p>
      </IconButton>
        <IconButton  onClick={()=>likePost()} sx={{marginLeft:1}}  aria-label="add to favorites">
          {likeClick?<FavoriteIcon  sx={{color:`${appTextColor}`}}  />:<FavoriteBorderIcon  sx={{color:`${appTextColor}`}}  />}
          <p style={{marginLeft:1,fontSize:13,color:`${appTextColor}`}}>{Divider(Number(likes)+like)}</p>
        </IconButton>
        <IconButton sx={{marginLeft:1}} aria-label="add to favorites">
          <VisibilityOutlinedIcon  sx={{color:`${appTextColor}`}}  />
          <p style={{marginLeft:1,fontSize:13,color:`${appTextColor}`}}>{Divider(views)}</p>
        </IconButton>

        <IconButton onClick={()=>bookmarkPost()} sx={{marginLeft:1}}  aria-label="add to favorites">
          {bookmarkClick?<TurnedInNotOutlinedIcon  sx={{color:`${appTextColor}`}}  />:<BookmarkBorderIcon sx={{color:`${appTextColor}`}} />}
          <p style={{marginLeft:1,fontSize:13,color:`${appTextColor}`}}>{Divider(Number(bookmarks) + bookmark)}</p>
        </IconButton>

        <IconButton onClick={()=>{dispatch(setShareLink(`https://premiumblognews.com/readnews/${link}`));dispatch(SetProductID(data.id));dispatch(setShareModal(true))}}  sx={{marginLeft:1}}  aria-label="add to favorites">
          <ShareIcon  sx={{color:`${appTextColor}`}}  />
          <p style={{marginLeft:1,fontSize:13,color:`${appTextColor}`}}>{Divider(Number(share))}</p>
        </IconButton>
    </div>
   
  );
}