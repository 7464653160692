import React,{useState,useEffect,useRef} from "react"
import Header from "./Header";
import NewsSkeleton from "../Home/Skeleton";
import Box from '@mui/material/Box';
import { useSelector} from 'react-redux';
import PeopleIcon from '@mui/icons-material/People';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import GroupsIcon from '@mui/icons-material/Groups';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useNavigate } from "react-router-dom";
import API from "../../API/API";
import { Divider } from "../../components/Controllers/NumberFormater";

const AdminPanel = () =>{

    const [isLoading, setLoading] = useState(true);
    const [data,setData] = useState([]);
    const userID = useSelector((data)=>data.appState.userID)
    const token = useSelector((data)=>data.appState.token)
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const WindowWith = windowSize.current[0]
    const navigate = useNavigate();

    const fetchAdminData = async () =>{

        const requestOptions = {
            method: 'POST',
            Accept:'application/json',
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': token
             },
            body: JSON.stringify({"userID":`${userID}`})
          };
          
     try {
          
        const response = await fetch(`${API.BASE_URL}/adminpanel`,requestOptions)
        const adminData = await response.json();
        setData(adminData);   
     
      } 
      catch (error) 
      {
        console.error(error);
      }
       finally 
       {
           setLoading(false)
          
      } 
     }
  
      useEffect(() =>{
      fetchAdminData();
       
    },[]);

    const AdminDashBoard = ({data})=>{
            

       
        return (
            <div>
                     <div onClick={()=>navigate("/PIDControllerX/visitorcontroller")}   style={{display:"flex",flexDirection:"row",marginTop:20}}>
                      <div style={{backgroundColor:"#FEF2E5",height:180,width:WindowWith * 0.43,marginLeft:20,borderRadius:10}}>
                            <p style={{fontSize:15,marginLeft:10,paddingTop:0,fontWeight:"bold"}}>VISITORS</p>
                            <p style={{fontSize:55,textAlign:"center",marginTop:10,marginBottom:1,fontWeight:"bold"}}>{Divider(data.visitorCounter)}</p>
                             <GroupsIcon  style={{marginLeft:WindowWith * 0.3,marginTop:35}}/>
                     </div>

                     <div style={{backgroundColor:"#FEF2E5",height:180,width:WindowWith * 0.43,marginLeft:10,borderRadius:10}}>
                            <p style={{fontSize:15,marginLeft:10,paddingTop:0,fontWeight:"bold"}}>USERS</p>
                            <p style={{fontSize:55,textAlign:"center",marginTop:10,marginBottom:1,fontWeight:"bold"}}>{Divider(data.usersCount)}</p>
                             <PeopleIcon  style={{marginLeft:WindowWith * 0.3,marginTop:35}}/>
                    </div>

                     </div>

                     <div style={{display:"flex",flexDirection:"row",marginTop:20}}>

                     <div onClick={()=>navigate("/PIDControllerX/adminnews")}  style={{backgroundColor:"#FDBE4A",height:180,width:WindowWith * 0.43,marginLeft:20,borderRadius:10}}>
                            <p  style={{fontSize:15,marginLeft:10,paddingTop:0,fontWeight:"bold"}}>NEWS</p>
                            <p style={{fontSize:55,marginTop:10,marginBottom:1,fontWeight:"bold",textAlign:"center"}}>{Divider(data.newsCount)}</p>
                             <NewspaperIcon  style={{marginLeft:WindowWith * 0.3,marginTop:35}}/>
                  
                    </div>
                    <div onClick={()=>navigate("/PIDControllerX/messages")}  style={{backgroundColor:"#FDBE4A",height:180,width:WindowWith * 0.43,marginLeft:10,borderRadius:10}}>
                            <p  style={{fontSize:15,marginLeft:10,paddingTop:0,fontWeight:"bold"}}>MESSAGES</p>
                            <p style={{fontSize:55,marginTop:10,marginBottom:1,fontWeight:"bold",textAlign:"center"}}>{Divider(data.messageCounter)}</p>
                             <MailOutlineIcon  style={{marginLeft:WindowWith * 0.3,marginTop:35}}/>
                  
                    </div>


                     </div>
                 
               

                    <div  onClick={()=>navigate("/PIDControllerX/createnews")} style={{display:"flex",backgroundColor:"#E4E4E4",height:60,width:WindowWith * 0.9,margin:20,borderRadius:5,alignItems:"center",justifyContent:"center"}}>
                            <p style={{fontSize:15,fontWeight:"bold",textAlign:"center"}}>CREATE NEWS</p>
                    </div>
                    <div style={{display:"flex",backgroundColor:"#E4E4E4",height:60,width:WindowWith * 0.9,margin:20,borderRadius:5,alignItems:"center",justifyContent:"center"}}>
                            <p style={{fontSize:15,fontWeight:"bold",textAlign:"center"}}>MESSAGES</p>
                    </div>
                    <div style={{display:"flex",backgroundColor:"#E4E4E4",height:60,width:WindowWith * 0.9,margin:20,borderRadius:5,alignItems:"center",justifyContent:"center"}}>
                            <p style={{fontSize:15,fontWeight:"bold",textAlign:"center"}}>MOST LIKE NEWS</p>
                    </div>
                    <div style={{display:"flex",backgroundColor:"#E4E4E4",height:60,width:WindowWith * 0.9,margin:20,borderRadius:5,alignItems:"center",justifyContent:"center"}}>
                            <p style={{fontSize:15,fontWeight:"bold",textAlign:"center"}}>CREATE USER</p>
                    </div>
                    
            </div>
        )
    }
  
   
    
    return(
        <Box>
          <Header />
          {isLoading?<NewsSkeleton isLoading={isLoading} />:<AdminDashBoard data = {data} />}
        </Box>
    )

}


export default AdminPanel;