import React,{useState,useEffect,useRef} from "react"
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import logo from "../../../assets/logo/logodp.png";
import { useDispatch,useSelector} from 'react-redux';
import { setFirstName,setLastName,setID,setIsLoggedIn,setIsLoggedOut,SetEmail,SetAdmin } from "../../../Redux/Reducers/appState";
import API from "../../../API/API";
import NewsSkeleton from "../../Home/Skeleton";
import EditIcon from '@mui/icons-material/Edit';

const  Profile = () => {

  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const WindowWith = windowSize.current[0]
  const navigate = useNavigate();
  const isLoggedOut = useSelector((data)=>data.appState.isLoggedOut)
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true);
  const [data,setData] = useState({});
  const userID = useSelector((data)=>data.appState.userID)
  const token = useSelector((data)=>data.appState.token)
  const fetchUser = async () =>{
   
    const requestOptions = {
      method: 'POST',
      Accept:'application/json',
      headers: { 
          'Content-Type': 'application/json',
          'Authorization':token,
       },
      body: JSON.stringify({"userID":`${userID}`})
    };
    
    try {
      const response = await fetch(`${API.BASE_URL}/getuser`,requestOptions)
      const res = await response.json();
      setData(res);   
   
    } 
    catch (error) 
    {
      console.error(error);
    }
     finally 
     {
      setLoading(false)
        
    } 
   }

    useEffect(() =>{
    fetchUser()
     
  },[]);


 
  const LogoutUser = async () =>
  {
    dispatch(setIsLoggedIn(false))
    dispatch(SetEmail("guess@gmail.com"))
    dispatch(setFirstName("guess"))
    dispatch(setLastName("guess"))
    dispatch(SetAdmin(false))
    dispatch(setIsLoggedOut(true)) //Prevent double login
    dispatch(setID(-1))
    setTimeout(()=>{
       navigate("/home")
   },1000)

}


const Card = ({data})=>{
    const user = data[0]
  return (
    <div style={{flex:1,height:"800px"}}>
         <div>
             {user.profilephoto===null?<img onClick={()=>navigate("/home")}  src={logo} style={{marginTop:"55px",marginLeft:WindowWith * 0.3,marginBottom:3}} height={130} width={130} alt='logo'/>:<img onClick={()=>navigate("/home")}  src={user.profilephoto} style={{marginTop:"55px",marginLeft:WindowWith * 0.3,marginBottom:3}} height={130} width={130} alt='logo'/>}
              <EditIcon />
         </div>
         <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{marginLeft:WindowWith * 0.08,display:'flex',marginTop:20}}>
           <p style={{fontStyle:'normal'}}>First Name:</p>
           <p style={{fontStyle:'nomral',fontWeight:'bold',marginLeft:'10px'}}>{user.firstName}</p>
           <EditIcon sx={{fontSize:'18px',marginTop:'12px',marginLeft:'10px'}} />
        </div>
        <div style={{marginLeft:WindowWith * 0.08,display:'flex',marginTop:-4}}>
           <p style={{fontStyle:'normal'}}>Last Name:</p>
           <p style={{fontStyle:'nomral',fontWeight:'bold',marginLeft:'10px'}}>{user.lastName}</p>
           <EditIcon sx={{fontSize:'18px',marginTop:'12px',marginLeft:'10px'}} />
        </div>

        <div style={{marginLeft:WindowWith * 0.08,display:'flex',marginTop:-4}}>
           <p style={{fontStyle:'normal'}}>Email:</p>
           <p style={{fontStyle:'nomral',fontWeight:'bold',marginLeft:'10px'}}>{user.email}</p>
           <EditIcon sx={{fontSize:'18px',marginTop:'12px',marginLeft:'10px'}} />
        </div>
       
        <LoadingButton
          size="small"
          onClick={()=>LogoutUser()}
          loading={isLoading}
          loadingIndicator="Loading…"
          variant="outlined"
          sx={{ mt:3,ml:WindowWith * 0.015, width: WindowWith * 0.8 ,p:2,borderColor:"black",color:"black"}} 
        >
          <span>Logout</span>
         </LoadingButton>
    </Box>

    </div>
  );



}


return (
  <div>
      {isLoading?<NewsSkeleton isLoading={isLoading} />:<Card  data = {data.result} />}
  </div>
);

}

export default Profile;