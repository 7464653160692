export const data = [{
    id:1,
    name:"Home",
},{
    id:2,
    name:"Tech",
},{
    id:3,
    name:"Sports",
},{
    id:4,
    name:"Jobs",
},{
    id:5,
    name:"Crypto",
}]