import React from "react"
import { useSelector } from 'react-redux';
import "./footer.css";
import logo from "../../assets/logo/logob.png"
import { useNavigate } from "react-router-dom";

const Footer =()=>
{

  //const appTextColor = useSelector((data)=>data.appState.appTextColor)
  //const appTheme = useSelector((data)=>data.appState.appTheme)
  const navigate = useNavigate();

    return(

               <div  className='footer'  >
                     <div className='footerContainer' >     

                          <div className="footerLogoContainer">
                           <img  width={70} height={70}  className='footerLogo' src={logo} alt="logo" />  
                           <div>            
                           <h1 className='quickLinksHeadText'>PremiumBlog</h1>
                           <p className='quickLinksText' >officialpremiumblog@gmail.com</p>
                          </div>
                          </div>

                           <div className="footerLinksContainer">
                           <h1 className='quickLinksHeadText' >Quick Links</h1>
                           <div className="quickLinksContainer">
                           <p className='quickLinksText' onClick={()=>navigate("/about")}  >About Us</p>
                           <p className='quickLinksText'>Contact Us</p>
                           <p className='quickLinksText' >Privacy Policy</p>
                           <p className='quickLinksText' >Terms & Conditions</p>
                           </div>
                           </div>

                          
                      </div>
                       
               </div>

    )
}

export default Footer;