
import React,{useState,useEffect} from "react";
import NewsSkeleton from "../../Home/Skeleton";
import VCard from "./Card";
import Header from "../Header";
import API from "../../../API/API";
import { useSelector } from "react-redux";


const VisitorController =()=>{

    const [isLoading, setLoading] = useState(true);
    const [data,setData] = useState({});
    const visitorId = useSelector((data)=>data.appState.visitorId)
    const token = useSelector((data)=>data.appState.token)
    const FetchProducts = async () =>{
     
      const requestOptions = {
        method: 'POST',
        Accept:'application/json',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization':token,
         },
        body: JSON.stringify({"visitorId":`${visitorId}`})
      };
      
      try {
        const response = await fetch(`${API.BASE_URL}/adminpanel/getvisitors`,requestOptions)
        const res = await response.json();
        setData(res);   
     
      } 
      catch (error) 
      {
        console.error(error);
      }
       finally 
       {
           setLoading(false)
          
      } 
     }
  
      useEffect(() =>{
      FetchProducts()
       
    },[]);

    const List = ({data})=>{
       
        return (
            <div>
            {
               data.map((data)=><VCard  IP = {data.ip} LAT={data.latitude} LOG={data.longitude} country={data.countryName}  city={data.city} date={data.date}  data={data} />)

            }
            </div>
        )
    }
  
    return (
      <div>
         <Header />
          {isLoading?<NewsSkeleton isLoading={isLoading} />:<List  data = {data.result} />}
      </div>
    );
  }

  export default VisitorController;