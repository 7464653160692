import React,{useState,useEffect,useRef} from "react"
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from "react-router-dom";

import LoadingButton from '@mui/lab/LoadingButton';

import logo from "../../../assets/logo/logo.png";

import API from "../../../API/API";
import { useDispatch } from "react-redux";
import { setSuccessMessage } from "../../../Redux/Reducers/appState";




const  CreateAccount = () => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const WindowWith = windowSize.current[0]
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [firstName,setFirstName] = useState("")
  const [lastName,setLastName] = useState("")
  const [email,setEmail] = useState("")
  const [password,setPassword] = useState("")
  const [confirmpassword, setConfirmPassword] = useState('Confirm password');
  const [isLoading, setLoading] = useState(false);
  const [mismatch,setMismatch] = useState(0)
  const [validation,setValidation]= useState(false)
  const [emailExisted,setEmailExisted] = useState(0)
  const [invalidFirstName,setInvalideFirstName] = useState(0)
  const [invalidLastName,setInvalideLastName] = useState(0)
  const [invalidEmail,setInvalideEmail] = useState(0)
  const [state,setState] = useState([]);
  const dispatch = useDispatch();
  const Register = async () =>
  {
    if(firstName===""){
        setValidation(false);
        setInvalideFirstName(1);
    }else {
       setInvalideFirstName(0);
       setValidation(true);
    }
    if(lastName===""){
        setValidation(false);
        setInvalideLastName(1);
    } else {
       setInvalideLastName(0);
       setValidation(true);
    }
    if(email===""){
        setValidation(false);
        setInvalideEmail(1);
    }else {
        setInvalideEmail(0);
        setValidation(true);
    }
    if(password!==confirmpassword){
        setValidation(false);
        setMismatch(1)
    }else if(password === confirmpassword){
       setMismatch(0)
       setValidation(true);
    }
   

   if(validation===true)
  {
      setLoading(true)
    const requestOptions = {
      method: 'POST',
      Accept:'application/json',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({"firstName":`${firstName}`,"lastName":`${lastName}`,"email":`${email}`,"password":`${password}`})
    };
    
    try {
    
      const response = await fetch(`${API.BASE_URL}/createaccount`,requestOptions)
      const json = await response.json();
      var data = json
      setState(data);   
   
    } 
    catch (error) 
    {
      console.error(error);
    }
     finally 
     {
         setLoading(false)
        
    } 
   }
}

    useEffect(()=>{
        if(state.status===1)
        {
         setEmailExisted(0)
         dispatch(setSuccessMessage("Account Created Successfully"))
         setTimeout(()=>{
           navigate("/successpage")
         },1000)

        }else if(state.status===404){
            setEmailExisted(1)
            setLoading(false)
        }
     
  },[state]);





  return (
    <div style={{flex:1,height:"800px"}}>
         <div>
              <img src={logo} style={{marginTop:"55px",marginLeft:WindowWith * 0.33,marginBottom:3}} height={130} width={WindowWith * 0.34} alt='logo'/>
         </div>
 <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <div>
        <TextField
          label="First Name"
          id="outlined-start-adornment"
          sx={{ mt:3,ml:WindowWith * 0.025, width:WindowWith * 0.6 }}
          onChange={(event)=>setFirstName(event.target.value)}
          required={true}
        />
                <p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:-5,marginBottom:-5,marginLeft:100}}>{invalidFirstName===1?<p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:10,marginBottom:-10,marginLeft:0}}>Please enter your firstname</p>:""}</p>
          <TextField
          label="Last Name"
          id="outlined-start-adornment"
          sx={{ mt:3,ml:WindowWith * 0.025, width:WindowWith * 0.6 }}
          onChange={(event)=>setLastName(event.target.value)}
          required={true}
        />
        <p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:-5,marginBottom:-5,marginLeft:100}}>{invalidLastName===1?<p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:10,marginBottom:-10,marginLeft:0}}>Please enter your lastname</p>:""}</p>

        <TextField
          label="Email"
          id="outlined-start-adornment"
          sx={{ mt:3,ml:WindowWith * 0.025, width:WindowWith * 0.6 }}
          onChange={(event)=>setEmail(event.target.value)}
          required={true}
        />
      
      <p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:-5,marginBottom:-5,marginLeft:100}}>{invalidEmail===1?<p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:10,marginBottom:-10,marginLeft:0}}>Please enter a valid email</p>:""}</p>
      <p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:-5,marginBottom:-5,marginLeft:100}}>{emailExisted===1?<p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:10,marginBottom:-10,marginLeft:0}}>{state.message}</p>:""}</p>
        <FormControl sx={{ mt:3,ml:WindowWith * 0.025, width:WindowWith * 0.6 }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            onChange={(event)=>setPassword(event.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
       </FormControl>
       <p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:-5,marginBottom:-5,marginLeft:100}}>{mismatch===1?<p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:10,marginBottom:-10,marginLeft:0}}>passwords mismatch</p>:""}</p>
        <FormControl sx={{ mt:3,ml:WindowWith * 0.025, width:WindowWith * 0.6}} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            onChange={(event)=>setConfirmPassword(event.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label="Confirrm Password"
          />
            </FormControl>
            <p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:-5,marginBottom:-5,marginLeft:100}}>{mismatch===1?<p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:10,marginBottom:-10,marginLeft:0}}>passwords mismatch</p>:""}</p>
           <LoadingButton
          size="small"
          onClick={()=>Register()}
          loading={isLoading}
          loadingIndicator="Loading…"
          variant="outlined"
          sx={{ mt:3,ml:WindowWith * 0.025, width: WindowWith * 0.6 ,p:2,borderColor:"black",color:"black"}} 
        >
          <span>Register</span>
        </LoadingButton>
      
      </div>
    </Box>

    </div>
  );
}

export default CreateAccount;