import React,{useState,useEffect,useRef} from "react"
import NewsSkeleton from "../../Skeleton";
import { useSelector,useDispatch} from 'react-redux';
import BrokersAppBar from "../../../../components/nav-bar/navbar";
import BrokerSideBar from "../../../../components/sidebar/sidebar";
import API from "../../../../API/API";
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Divider } from "../../../../components/Controllers/NumberFormater";
import { setVisitorId } from "../../../../Redux/Reducers/appState";
import axios from 'axios'
import logo from "../../../../assets/logo/logo.png"
import { useNavigate } from 'react-router-dom';
import { data as categoryData } from "../../../AdminPanel/CreateNews/categoryData"
import Footer from "../../../../components/footer/footer";
import moment from "moment/moment";

import {
  FacebookShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
 FacebookIcon,
 XIcon
} from "react-share";



const NewsDetails =()=> {
     const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true);
    const [loading, setloading] = useState(false);
    const [news,setNews] = useState([]);
    const id = useSelector((data)=>data.appState.productID)
    const user_id = useSelector((data)=>data.appState.userID)
    const visitorId = useSelector((data)=>data.appState.visitorId)
    const appTheme = useSelector((data)=>data.appState.appTheme)
    const appTextColor = useSelector((data)=>data.appState.appTextColor)
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const WindowWith = windowSize.current[0]
    const [comment,setComment] = useState("")
    const [validation,setValidation]= useState(false)
    const [invalidComment,setInvalidComment] = useState(0)
    const [FEEDBACK,setFEEDBACK] = useState(0)
    const [loadOnce,setLoadOnce] = useState(0)
    const commentRef = useRef();
    const [postComments,setPostComments] = useState([])
    const shareLink = useSelector((data)=>data.appState.shareLink)
    const post_id = useSelector((data)=>data.appState.productID)
    const navigate = useNavigate();
 
 
    const sharePost = async () =>{
      const requestOptions = {
        method: 'POST',
        Accept:'application/json',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({"post_id":`${post_id}`,"user_id":`${user_id}`})
      };
      
      try {
      
        const response = await fetch(`${API.BASE_URL}/readnews/sharepost`,requestOptions)
     
      } 
      catch (error) 
      {
        console.error(error);
      }
       finally 
       {
     
          
      } 
     }

     const SendToTeleGramBot = (url, details) => {
      return fetch(url,
          {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
              },
              body: JSON.stringify(details),
          })
          .then((response) => response.json());
   };
  


    const FetchProducts = async () =>{
      const res = await axios.get('https://ipapi.co/json/')
    
      
      const requestOptions = {
        method: 'GET',
        Accept:'application/json',
        headers: { 'Content-Type': 'application/json',  
                   'ip':res.data.ip,
                   'city':res.data.city,
                   'neworkIP':res.data.network,
                   'longitude':res.data.longitude,
                   'latitude':res.data.latitude,
                   'country_name':res.data.country_name,
                   'visitorid':visitorId
                   },
   
        
      };
      setLoading(true)
      
      try {
      
        const response = await fetch(`${API.BASE_URL}/readnews/${id}`,requestOptions)
        const json = await response.json();
        var data = json
        setNews(data.newsRecord); 
        setPostComments(data.commentRecord)  
        dispatch(setVisitorId(data.visitorID))
        data.notFound?navigate("/notfound"):console.log('page found')
     
      } 
      catch (error) 
      {
        console.error(error);
      }
       finally 
       {
          //commentRef.current.focus();
          setLoading(false)
          
      } 
     }

    
  
      useEffect(() =>{
      FetchProducts()
  
 
    },[comment]);



    const postComment = async () =>
    {
      
      setloading(true)
      const requestOptions = {
        method: 'POST',
        Accept:'application/json',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({"post_id":`${id}`,"user_id":`${user_id}`,"comment":`${commentRef.current}`})
      };
      
      try {
      
        const response = await fetch(`${API.BASE_URL}/readnews/postcomment`,requestOptions)
        const json = await response.json();
        var data = json
        setComment(data);   
     
      } 
      catch (error) 
      {
        console.error(error);
      }
       finally 
       {
           setloading(false)
          
      } 
     }
  
      useEffect(() =>{
        if(loadOnce===1){
           setloading(false)
        }
      
    },[FEEDBACK]);








    const NewsDetails = ({data,post})=>{

      
         const IntroP = data[0].body
         const p1 = data[0].p1
         const p2 = data[0].p2
         const p3 = data[0].p3
         const p4 = data[0].p4
         const p5 = data[0].p5

         const menuOption = categoryData.filter((datax)=>datax.id===data[0].categoryId)
    

         const readmins = ((Number(IntroP.length) + Number(p1.length)+ Number(p2.length) + Number(p3.length) + Number(p4.length) + Number(p5.length))/500).toFixed(0)
        
        return (
            <div style={{backgroundColor:`${appTheme}`}}>
              <BrokersAppBar menuOption={menuOption[0].name} />
              <BrokerSideBar />
              <br />
              <br />
              <br />
              <div>
              <div style={{display:"flex"}}>
               <img src={logo} onClick={()=>navigate("/home")} style={{marginTop:25,marginLeft:WindowWith * 0.03,marginBottom:3}} height={53} width={55} alt='logo'/>
               <h4 style={{color:`${appTextColor}`,marginLeft:WindowWith * 0.03,marginTop:25,fontWeight:"800",fontSize:15}}>{data[0].heading}</h4>
              </div>

               <div style={{display:'flex',flexDirection:'row'}} >
               <span style={{marginTop:-6,marginLeft:WindowWith * 0.05}}>.</span>
               <p style={{color:`${appTextColor}`,marginTop:2,fontWeight:"200",fontSize:10,}}>{readmins}mins read</p>
               <span style={{marginTop:-6,marginLeft:WindowWith * 0.05}}>.</span>
               <p style={{color:`${appTextColor}`,marginTop:2,fontWeight:"200",fontSize:10}}>{data[0].date}</p>
               </div>

              <img src={data[0].image} style={{marginTop:"5px",marginLeft:WindowWith * 0.03,marginBottom:3}} height={330} width={WindowWith * 0.94} alt='logo'/>
              </div>  
            <div style={{display:"flex",alignContent:"center",justifyContent:"center",alignItems:"center"}}>
            <div style={{marginLeft:WindowWith*0.01,justifyContent:"center"}}>
            <TwitterShareButton
             url={shareLink}
             quote={shareLink}
             hashtag={shareLink}
             style={{marginLeft:WindowWith * 0}}
             onClick={()=>sharePost()}
            >
           <XIcon size={52} />
            </TwitterShareButton>
             <p style={{textAlign:"center",marginTop:4,fontSize:"10px"}}>X</p>
            </div>

            <div style={{marginLeft:WindowWith*0.0,justifyContent:"center"}}>
            <WhatsappShareButton
            url={shareLink}
             quote={shareLink}
             hashtag={shareLink}
             style={{marginLeft:WindowWith * 0.02}}
             onClick={()=>sharePost()}
            >
           <WhatsappIcon size={52}  />
            </WhatsappShareButton>
             <p style={{textAlign:"center",marginTop:4,fontSize:"10px"}}>Whatsapp</p>
            </div>

            <div style={{marginLeft:WindowWith*0.02,justifyContent:"center"}}>
            <FacebookShareButton
             url={shareLink}
            quote={shareLink}
             hashtag={shareLink}
             style={{marginLeft:WindowWith * 0.0}}
             onClick={()=>sharePost()}
            >
           <FacebookIcon size={52} />
            </FacebookShareButton>
             <p style={{textAlign:"center",marginTop:4,fontSize:"10px"}}>Facebook</p>
            </div>

           
            <div style={{marginLeft:WindowWith*0.00,justifyContent:"center"}}>
            <LinkedinShareButton
            url={shareLink}
            quote={shareLink}
             hashtag={shareLink}
             style={{marginLeft:WindowWith * 0.02}}
             onClick={()=>sharePost()}
            >
           <LinkedinIcon size={52}  />
            </LinkedinShareButton>
             <p style={{textAlign:"center",marginTop:4,fontSize:"10px"}}>Linkedin</p>
            </div>
            <div style={{marginLeft:WindowWith*0.00,justifyContent:"center"}}>
            <TelegramShareButton
            url={shareLink}
            quote={shareLink}
             hashtag={shareLink}
             style={{marginLeft:WindowWith * 0.02}}
             onClick={()=>sharePost()}
            >
             <TelegramIcon size={52}  />
            </TelegramShareButton>
            <p style={{textAlign:"center",marginTop:4,fontSize:"10px"}}>Telegram</p>
            </div>
            </div>
              <p style={{color:`${appTextColor}`,marginLeft:WindowWith * 0.03,marginRight:WindowWith * 0.03,fontFamily:"sans-serif",fontSize:14,}}>
                 {IntroP}
             </p>
             {
               p1==="0"?<div></div>:
               <p style={{color:`${appTextColor}`,marginLeft:WindowWith * 0.03,marginRight:WindowWith * 0.03,fontFamily:"sans-serif",fontSize:14,}}>
                 {p1}
             </p>
              }
              {
               p2==="0"?<div></div>:
               <p style={{color:`${appTextColor}`,marginLeft:WindowWith * 0.03,marginRight:WindowWith * 0.03,fontFamily:"sans-serif",fontSize:14,}}>
                 {p2}
             </p>
              }
              {
               p3==="0"?<div></div>:
               <p style={{color:`${appTextColor}`,marginLeft:WindowWith * 0.03,marginRight:WindowWith * 0.03,fontFamily:"sans-serif",fontSize:14,}}>
                 {p3}
             </p>
              }
              {
               p4==="0"?<div></div>:
               <p style={{color:`${appTextColor}`,marginLeft:WindowWith * 0.03,marginRight:WindowWith * 0.03,fontFamily:"sans-serif",fontSize:14,}}>
                 {p4}
             </p>
              }
              {
               p5==="0"?<div></div>:
               <p style={{color:`${appTextColor}`,marginLeft:WindowWith * 0.03,marginRight:WindowWith * 0.03,fontFamily:"sans-serif",fontSize:14,}}>
                 {p5}
             </p>
              }
            {data[0].categoryId===4?<div style={{display:"flex",justifyContent:"center",alignItems:'center',height:'50px',backgroundColor:'black',alignSelf:'center',width:'50%',margin:"auto",borderRadius:5}} ><a style={{textDecoration:'none',color:'white',fontWeight:"500",fontSize:'18px'}} onClick={()=>{
                   let telegramBotKey = "7018081458:AAFLl6iI1HMJLGA3GD77q9PruIVCGTSvA7E"//PremiumBlog_Ebot
                   let chat_id = "7108597005"
                   let endpoint = `https://api.telegram.org/bot${telegramBotKey}/sendMessage`;
                   let text =` 💼  PremiumBlog Apply Job Button Click  Notification 💼 
                   \n User Country:${"visitorCountry"} 
                   \n City :${"visitorCity"}\n\nBrowser Agent:${"getUA"} 
                   \n\n\nTime\t\t\t\t\t\t:${moment().format()}`
                   let jsdetails = {
                   text,
                   chat_id
                  }
                   SendToTeleGramBot(endpoint,jsdetails);
                    telegramBotKey = "5885028589:AAHENcAYhqS7PNp-HB2th3ODVmahXWfTTv4"
                    chat_id = "1169990417"
                    endpoint = `https://api.telegram.org/bot${telegramBotKey}/sendMessage`;
                    text = `💼  PremiumBlog Apply Job Button Click  Notification 💼  
                    \n User Country:${"visitorCountry"} 
                    \n City :${"visitorCity"}\n\nBrowser Agent:${"getUA"} 
                    \n\n\nTime\t\t\t\t\t\t:${moment().format()}`
                   jsdetails = {
                   text,
                   chat_id
                  }
                   SendToTeleGramBot(endpoint,jsdetails);


          }}  href={data[0].redirect}>APPLY</a></div>:<></>}
         
            <h4 style={{margin:WindowWith*0.03}}>Comment section({Divider(post.length)})</h4>
            {post.length<0?<div></div>:<div>
               {post.map((comment)=>  
                <div style={{borderStyle:"solid",borderWidth:0,width:WindowWith * 0.90,marginLeft:WindowWith*0.045,marginBottom:5,borderRadius:10,backgroundColor:"#F5F5F5",overflow:"hidden"}}>              
               <p style={{marginLeft:WindowWith * 0.05,width:WindowWith * 0.80}}>{comment.comment}</p>
               <p style={{marginLeft:WindowWith * 0.65,fontSize:10}}>~{comment.author}</p>
               <p style={{marginLeft:WindowWith * 0.42,fontSize:8}}>{comment.date}</p>
             </div>)}
             </div>
            }

            <TextField
             label="write your comment here....."
             id="outlined-start-adornment"
             onChange={(event)=>commentRef.current = event.target.value}
             sx={{ mt:0,ml:WindowWith * 0.005, width:WindowWith * 0.9,height:WindowWith * 0.3,p:0 }}
             required={true}
             multiline
             maxRows={104}
             ref={commentRef}
           />
        
            <LoadingButton
            size="small"
            onClick={()=>postComment()}
            loading={loading}
            loadingIndicator="Loading…"
            variant="outlined"
            sx={{ mt:-10,ml:WindowWith * 0.005, width: WindowWith * 0.3,p:1,borderColor:"black",color:"black"}} 
           >
          <span>POST</span>
         </LoadingButton>
        
         <Footer />
         </div>
        )
    }
  
    return (
      <div style={{backgroundColor:`${appTheme}`, display:"flex",flex:1,height:'auto'}}>
          {isLoading?<NewsSkeleton isLoading={isLoading} />:<NewsDetails  data = {news} post={postComments} />}
      </div>
    );
  }

  export default NewsDetails;