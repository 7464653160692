import Box from '@mui/material/Box';
import React,{useRef} from "react"
import { useSelector} from 'react-redux';
import logo from "../../assets/logo/logo.png"
import logob from "../../assets/logo/logob.png"
import LinearProgress from '@mui/material/LinearProgress';


const NewsSkeleton = () => {
   
    const appTheme = useSelector((data)=>data.appState.appTheme)
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const WindowWith = windowSize.current[0]
    return ( 
    
           <Box sx={{ width: '100%',height:'auto'}}>
           {appTheme==='white'?<img src={logo} style={{marginTop:WindowWith * 0.54,marginLeft:WindowWith * 0.34,marginBottom:20}} height={WindowWith * 0.34} width={WindowWith * 0.34} alt='logo'/>:<img src={logob} style={{marginTop:WindowWith * 0.54,marginLeft:WindowWith * 0.34,marginBottom:20}} height={WindowWith * 0.34} width={WindowWith * 0.34} alt='logo'/>}
           <LinearProgress color="inherit" sx={{width:'80%',marginLeft:WindowWith * 0.015,}} />
           </Box>
      
     );
}
 
export default  NewsSkeleton;