import  React,{useState} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import ForumIcon from '@mui/icons-material/Forum';
import { useSelector,useDispatch } from 'react-redux';
import { setSideBarState } from '../../Redux/Reducers/appState';
import logo from "../../assets/logo/logo.png";
import logob from "../../assets/logo/logob.png";
import { setAppTheme,setAppTextColor } from '../../Redux/Reducers/appState';
import { useNavigate } from "react-router-dom";
import ListAltIcon from '@mui/icons-material/ListAlt';
import CopyrightIcon from '@mui/icons-material/Copyright';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import StarPurple500Icon from '@mui/icons-material/StarPurple500';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import PersonAddIcon from '@mui/icons-material/PersonAdd';//Create account icon
import AccountCircleIcon from '@mui/icons-material/AccountCircle';//Login icon
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';//contact us icon
import WidgetsIcon from '@mui/icons-material/Widgets';//Admin Panel settings

const BrokerSideBar=()=> {
  const state = useSelector((data)=>data.appState.SideBarState)
  const appTheme = useSelector((data)=>data.appState.appTheme)
  const appTextColor = useSelector((data)=>data.appState.appTextColor)
  const isLoggedIn = useSelector((data)=>data.appState.isLoggedIn )
  const admin = useSelector((data)=>data.appState.admin)
  const dispatch = useDispatch();
  const [openThemeMenu,setOpenThemeMenu] = useState(false);
  const [openTravel,setOpenTravel] = useState(false); 
  const [openEntertainmentMenu,setOpenEntertainmentMenu] = useState(false); 
  const [openLifeStyleMenu,setOpenLifeStyleMenu] = useState(false); 
  const [openAccount,setOpenAccountMenu] = useState(false); 
  const [openPrivacyPolicy,setOpenPrivacyPolicy] = useState(false); 
  const navigate = useNavigate();

  

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 ,backgroundColor:`${appTheme}`}}
      role="presentation"
    
    >
      <div style={{backgroundColor:`${appTheme}`,height:"1700px"}}>


      <List>
         <div>
              {appTheme==='white'?<img onClick={()=>navigate("/home")}  src={logo} style={{marginLeft:"45px",marginBottom:3}} height={130} width={130} alt='logo'/>:<img onClick={()=>navigate("/home")}  src={logob} style={{marginLeft:"45px",marginBottom:3}} height={130} width={130} alt='logo'/> }
         </div>
         <div>
            <div style={{backgroundColor:"black"}}>
           <ListItemButton  onClick={()=>setOpenThemeMenu(~openThemeMenu)} >
              <ListItemIcon sx={{ color: `white` }} >
                {openThemeMenu?<KeyboardArrowDownIcon sx={{ color: `white` }}  />:<KeyboardArrowRightIcon sx={{ color: `white` }}  />}
              </ListItemIcon>
              <ListItemText  sx={{ color: `white` }}  primary={"Theme"} />
            </ListItemButton>
            </div>
             {openThemeMenu?
             <div style={{display:"flow"}}>
             <ListItemButton onClick={()=>{dispatch(setAppTheme("#0b1118"));dispatch(setAppTextColor("white"))}} >
              <ListItemIcon>
                 <TurnedInIcon  sx={{ color: `${appTextColor}` }}  />
              </ListItemIcon>
              <ListItemText  sx={{ color: `${appTextColor}` }}  primary={"Dark"} />
              <p style={{fontSize:"10px",borderStyle:"solid",borderColor:'green',borderWidth:1,padding:4,borderRadius:20}}>Experimental</p>
            </ListItemButton>

            <ListItemButton onClick={()=>{dispatch(setAppTheme("white"));dispatch(setAppTextColor("black"))}} >
              <ListItemIcon>
                 <BookmarkBorderOutlinedIcon sx={{ color: `${appTextColor}` }}  />
              </ListItemIcon>
              <ListItemText sx={{ color: `${appTextColor}` }}  primary={"Light"} />
            </ListItemButton>
             </div>:<div></div>}
         </div>
    
      </List>
      <List>
      <div>
            <div style={{backgroundColor:"black",marginTop:-20}}>
           <ListItemButton  onClick={()=>setOpenTravel(~openTravel)} >
              <ListItemIcon sx={{ color: `white` }} >
              {openTravel?<KeyboardArrowDownIcon sx={{ color: `white` }}  />:<KeyboardArrowRightIcon sx={{ color: `white` }}  />}
              </ListItemIcon>
              <ListItemText  sx={{ color: `white` }}  primary={"Travel & Vacation"} />
            </ListItemButton>
            </div>
             {openTravel?
             <div style={{display:"flow"}}>
            
             <ListItemButton  onClick={()=>{navigate("home")}}>
              <ListItemIcon>
                  <FlightTakeoffIcon  sx={{ color: `${appTextColor}` }} />
              </ListItemIcon>
              <ListItemText   sx={{ color: `${appTextColor}`}}  primary={"Europe EU"} />
            </ListItemButton>
         
            <ListItemButton onClick={()=>{navigate("/signin")}}  >
              <ListItemIcon>
              <FlightTakeoffIcon  sx={{ color: `${appTextColor}` }} />
              </ListItemIcon>
              <ListItemText sx={{ color: `${appTextColor}` }}  primary={"North America"} />
            </ListItemButton>

            <ListItemButton onClick={()=>{navigate("/signup")}} >
              <ListItemIcon>
              <FlightTakeoffIcon  sx={{ color: `${appTextColor}` }} />
              </ListItemIcon>
              <ListItemText sx={{ color: `${appTextColor}` }}  primary={"Australia"} />
            </ListItemButton>

            <ListItemButton onClick={()=>{navigate("/payout")}} >
              <ListItemIcon>
              <FlightTakeoffIcon  sx={{ color: `${appTextColor}` }} />
              </ListItemIcon>
              <ListItemText sx={{ color: `${appTextColor}` }}  primary={"Antarctica"} />
            </ListItemButton>

            <ListItemButton  onClick={()=>{navigate("/about")}}>
              <ListItemIcon>
              <FlightTakeoffIcon  sx={{ color: `${appTextColor}` }} />
              </ListItemIcon>
              <ListItemText sx={{ color: `${appTextColor}` }}  primary={"South America"} />
            </ListItemButton>
            
            <ListItemButton  onClick={()=>{navigate("/contact")}}>
              <ListItemIcon>
              <FlightTakeoffIcon  sx={{ color: `${appTextColor}` }} />
              </ListItemIcon>
              <ListItemText sx={{ color: `${appTextColor}` }}  primary={"Africa"} />
            </ListItemButton>

            <ListItemButton onClick={()=>{navigate("/privacypolicy")}} >
              <ListItemIcon>
                 <LockOpenOutlinedIcon  sx={{ color: `${appTextColor}` }} />
              </ListItemIcon>
              <ListItemText sx={{ color: `${appTextColor}` }}  primary={"Privacy Policy"} />
            </ListItemButton>

            <ListItemButton  >
              <ListItemIcon>
                 <FolderOutlinedIcon  sx={{ color: `${appTextColor}` }} />
              </ListItemIcon>
              <ListItemText sx={{ color: `${appTextColor}` }}  primary={"Terms & Condition"} />
            </ListItemButton>

             </div>:<div style={{backgroundColor:"red",marginTop:-20}}></div>}

             
             </div>
      </List>

      <List>
      <div>
            <div style={{backgroundColor:"black"}}>
           <ListItemButton  onClick={()=>setOpenEntertainmentMenu(~openEntertainmentMenu)} >
              <ListItemIcon sx={{ color: `white` }} >
                 {openEntertainmentMenu?<KeyboardArrowDownIcon sx={{ color: `white` }}  />:<KeyboardArrowRightIcon sx={{ color: `white` }}  />}
              </ListItemIcon>
              <ListItemText  sx={{ color: `white` }}  primary={"Entertainment"} />
            </ListItemButton>
            </div>
             {openEntertainmentMenu?
             <div style={{display:"flow"}}>
             <ListItemButton onClick={()=>{navigate("/copytrading")}} >
              <ListItemIcon>
                  <StarPurple500Icon  sx={{ color: `${appTextColor}` }} />
              </ListItemIcon>
              <ListItemText  sx={{ color: `${appTextColor}` }}  primary={"Celebrities"} />
            </ListItemButton>

            <ListItemButton onClick={()=>{navigate("/tradinghelp")}} >
              <ListItemIcon>
                 <LibraryMusicIcon    sx={{ color: `${appTextColor}` }} />
              </ListItemIcon>
              <ListItemText sx={{ color: `${appTextColor}` }}  primary={"Musics"} />
            </ListItemButton>

            <ListItemButton onClick={()=>{navigate("/tradeforex")}} >
              <ListItemIcon>
                 <LocalMoviesIcon  sx={{ color: `${appTextColor}` }} />
              </ListItemIcon>
              <ListItemText sx={{ color: `${appTextColor}` }}  primary={"Movies"} />
            </ListItemButton>

        

             </div>:<div></div>}

             
             </div>
      </List>


      <List>
      <div style={{marginTop:-20}}>
            <div style={{backgroundColor:"black"}}>
           <ListItemButton  onClick={()=>setOpenLifeStyleMenu(~openLifeStyleMenu)} >
              <ListItemIcon sx={{ color: `white` }} >
                {openLifeStyleMenu?<KeyboardArrowDownIcon sx={{ color: `white` }}  />:<KeyboardArrowRightIcon sx={{ color: `white` }}  />}
              </ListItemIcon>
              <ListItemText  sx={{ color: `white` }}  primary={"Lifestyle"} />
            </ListItemButton>
            </div>
             {openLifeStyleMenu?
             <div style={{display:"flow"}}>

              <ListItemButton  onClick={()=>{navigate("/mininghelp")}} >
              <ListItemIcon>
                 <ForumIcon   sx={{ color: `${appTextColor}` }} />
              </ListItemIcon>
              <ListItemText sx={{ color: `${appTextColor}` }}  primary={"Fashion"} />
            </ListItemButton>

           
            <ListItemButton  onClick={()=>{navigate("/aboutmining")}} >
              <ListItemIcon>
                 <ListAltIcon  sx={{ color: `${appTextColor}` }} />
              </ListItemIcon>
              <ListItemText sx={{ color: `${appTextColor}` }}  primary={"Beauty & Health"} />
            </ListItemButton>

            <ListItemButton  onClick={()=>{navigate("/bitcoinmining")}} >
              <ListItemIcon>
                 <VolunteerActivismIcon  sx={{ color: `${appTextColor}` }} />
              </ListItemIcon>
              <ListItemText sx={{ color: `${appTextColor}` }}  primary={"Relationship & Weddings"} />
            </ListItemButton>

            <ListItemButton  onClick={()=>{navigate("/dogecoinmining")}} >
              <ListItemIcon>
                 <CopyrightIcon sx={{ color: `${appTextColor}` }} />
              </ListItemIcon>
              <ListItemText sx={{ color: `${appTextColor}` }}  primary={"Culture"} />
            </ListItemButton>
             </div>:<div></div>}

             
             </div>
      
      </List>



      <List>
      <div style={{marginTop:-20}}>
            <div style={{backgroundColor:"black"}}>
           <ListItemButton  onClick={()=>setOpenAccountMenu(~openAccount)} >
              <ListItemIcon sx={{ color: `white` }} >
                 {openAccount?<KeyboardArrowDownIcon sx={{ color: `white` }}  />:<KeyboardArrowRightIcon sx={{ color: `white` }}  />}
              </ListItemIcon>
              <ListItemText  sx={{ color: `white` }}  primary={"Account"} />
            </ListItemButton>
            </div>
             {openAccount?
             <div style={{display:"flow"}}>

              <ListItemButton  onClick={()=>{navigate("/createaccount")}} >
              <ListItemIcon>
                 <PersonAddIcon   sx={{ color: `${appTextColor}` }} />
              </ListItemIcon>
              <ListItemText sx={{ color: `${appTextColor}` }}  primary={"Create Account"} />
            </ListItemButton>
            
           
           {isLoggedIn?
            <ListItemButton  onClick={()=>{navigate("/profile")}} >
              <ListItemIcon>
                 <AccountCircleIcon  sx={{ color: `${appTextColor}` }} />
              </ListItemIcon>
              <ListItemText sx={{ color: `${appTextColor}` }}  primary={"Profile"} />
            </ListItemButton>:
            <ListItemButton  onClick={()=>{navigate("/login")}} >
              <ListItemIcon>
                 <AccountCircleIcon  sx={{ color: `${appTextColor}` }} />
              </ListItemIcon>
              <ListItemText sx={{ color: `${appTextColor}` }}  primary={"Login"} />
            </ListItemButton>
            }

            <ListItemButton  onClick={()=>{navigate("/bitcoinmining")}} >
              <ListItemIcon>
                 <ContactPhoneIcon  sx={{ color: `${appTextColor}` }} />
              </ListItemIcon>
              <ListItemText sx={{ color: `${appTextColor}` }}  primary={"Contact US"} />
            </ListItemButton>
            {admin===1?<ListItemButton  onClick={()=>{navigate("/PIDControllerX")}} >
              <ListItemIcon>
                 <WidgetsIcon sx={{ color: `${appTextColor}` }} />
              </ListItemIcon>
              <ListItemText sx={{ color: `${appTextColor}` }}  primary={"Admin Panel"} />
            </ListItemButton>:<div></div>}
          
             </div>:<div></div>}

             
             </div>
      
      </List>


      <List>
      <div style={{marginTop:-20}}>
            <div style={{backgroundColor:"black"}}>
           <ListItemButton  onClick={()=>setOpenPrivacyPolicy(~openPrivacyPolicy)} >
              <ListItemIcon sx={{ color: `white` }} >
                {openPrivacyPolicy?<KeyboardArrowDownIcon sx={{ color: `white` }}  />:<KeyboardArrowRightIcon sx={{ color: `white` }}  />}
              </ListItemIcon>
              <ListItemText  sx={{ color: `white` }}  primary={"Privacy Policy"} />
            </ListItemButton>
            </div>
             {openPrivacyPolicy?
             <div style={{display:"flow"}}>

              <ListItemButton  onClick={()=>{navigate("/mininghelp")}} >
              <ListItemIcon>
                 <ForumIcon   sx={{ color: `${appTextColor}` }} />
              </ListItemIcon>
              <ListItemText sx={{ color: `${appTextColor}` }}  primary={"Privacy Policy"} />
            </ListItemButton>

           
            <ListItemButton  onClick={()=>{navigate("/aboutmining")}} >
              <ListItemIcon>
                 <ListAltIcon  sx={{ color: `${appTextColor}` }} />
              </ListItemIcon>
              <ListItemText sx={{ color: `${appTextColor}` }}  primary={"Terms & Conditions"} />
            </ListItemButton>
          
            
             </div>:<div></div>}

             
             </div>
      
      </List>

      <List>
      <div style={{marginTop:-20}}>
            <div style={{backgroundColor:"black"}}>
           <ListItemButton   >
              <ListItemIcon sx={{ color: `white` }} >
                <VolunteerActivismIcon sx={{ color: `white` }}  />
              </ListItemIcon>
              <ListItemText  sx={{ color: `white` }}  primary={"Donate"} />
            </ListItemButton>
            </div>
             </div>
      
      </List>
      </div>
    </Box>
  );

  return (
    <div >
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state}
            onClose={()=>dispatch(setSideBarState(false))}
        
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}

      
    </div>
  );
}

export default BrokerSideBar;