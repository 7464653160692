import React from 'react'
import BrokerSideBar from '../../components/sidebar/sidebar';
import BrokersAppBar from '../../components/nav-bar/navbar';
import Footer from '../../components/footer/footer';
import './About.css'


const About = () =>{

    return(
        <div>
        <BrokersAppBar menuOption="Home" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className='container1'>
            <h1>Website Name:</h1>
            <p>PremiumBlog News</p>
        </div>
        <div className='container1'>
            <h1>Website Address:</h1>
            <p>www.premiumblognews.com</p>
        </div>
        <div className='container1'>
            <h1>Publisher's Name:</h1>
            <p>PremiumBlog LLC</p>
        </div>
        <div className='container1'>
            <h1>Company Base:</h1>
            <p>Lagos NG Ajah </p>
        </div>
        <div className='container1'>
            <h1>Email:</h1>
            <p>offficialpremiumblog@gmail.com</p>
        </div>
        <div className='container1'>
            <h1>Phone:</h1>
            <p>+2349115180606</p>
        </div>
        <br />
        <br />
        <div className='container1'>
            <h1>IT Officer:</h1>
            <p>Badare Okiemute Kelvin</p>
        </div>
        <div className='container1'>
            <h1>Email:</h1>
            <p>offficialpremiumblog@gmail.com</p>
        </div>
        <div className='container1'>
            <h1>Phone:</h1>
            <p>+2349115180606</p>
        </div>
        <br />
        <br />
        <div className='container1'>
            <h1>Launch date:</h1>
            <p>August 16, 2023</p>
        </div>

        <div className='container1'>
            <h1>What we do:</h1>
            <p></p>
        </div>
        <div style={{marginTop:20}}>
        <br />
  
        <br />
        <BrokerSideBar />
        </div>



        <Footer />
        </div>
    )


}


export default About;