import React,{useRef} from "react"
import { useNavigate } from "react-router-dom";
import { setShareModal } from "../../../Redux/Reducers/appState";
import { useDispatch,useSelector } from "react-redux";
import Drawer from '@mui/material/Drawer';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import API from "../../../API/API";
import {
  FacebookShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  XIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,

 FacebookIcon
} from "react-share";


  const ShareModal = () => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const WindowWith = windowSize.current[0]

  const shareModal = useSelector((data)=>data.appState.shareModal)
  const shareLink = useSelector((data)=>data.appState.shareLink)
  const user_id = useSelector((data)=>data.appState.userID)
  const post_id = useSelector((data)=>data.appState.productID)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sharePost = async () =>{
    const requestOptions = {
      method: 'POST',
      Accept:'application/json',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({"post_id":`${post_id}`,"user_id":`${user_id}`})
    };
    
    try {
    
      const response = await fetch(`${API.BASE_URL}/readnews/sharepost`,requestOptions)
   
    } 
    catch (error) 
    {
      alert(error)
    }
     finally 
     {
   
        
    } 
   }


  return (
          <React.Fragment >
          <Drawer
            anchor={'bottom'}
            open={shareModal}
            onClose={()=>dispatch(setShareModal(false))}
          >
            <div style={{height:420,width:WindowWith,borderTopLeftRadius:40,borderTopRightRadius:40,backgroundColor:"whitesmoke"}}>
          
            
            <p style={{marginTop:15,textAlign:"center"}}>Share post</p> 
             <div style={{display:"flex",flexDirection:"row",marginTop:WindowWith * 0.08,marginBottom:WindowWith * 0.08}}>
             <div style={{marginLeft:WindowWith * 0.2}} onClick={()=>{sharePost();navigator.clipboard.writeText(shareLink)}} >
             <ContentCopyIcon sx={{fontSize:40,marginLeft:WindowWith * 0.005}} />
             <p>Copy Link</p>
             </div>
             <div style={{marginLeft:WindowWith*0.2,justifyContent:"center"}}>
            <TelegramShareButton
            url={shareLink}
            quote={shareLink}
             hashtag={shareLink}
             style={{marginLeft:WindowWith * 0.02}}
             onClick={()=>sharePost()}
            >
             <TelegramIcon size={52} round />
            </TelegramShareButton>
             <p style={{textAlign:"center",marginTop:4}}>Share Via</p>
            </div>
             </div>

            <div style={{display:"flex",alignContent:"center",justifyContent:"center",alignItems:"center"}}>
            <div style={{marginLeft:WindowWith*0.04,justifyContent:"center"}}>
            <TwitterShareButton
             url={shareLink}
             quote={shareLink}
             hashtag={shareLink}
             style={{marginLeft:WindowWith * 0.02}}
             onClick={()=>sharePost()}
            >
           <XIcon size={52} round />
            </TwitterShareButton>
             <p style={{textAlign:"center",marginTop:4}}>X</p>
            </div>

            <div style={{marginLeft:WindowWith*0.04,justifyContent:"center"}}>
            <WhatsappShareButton
            url={shareLink}
             quote={shareLink}
             hashtag={shareLink}
             style={{marginLeft:WindowWith * 0.02}}
             onClick={()=>sharePost()}
            >
           <WhatsappIcon size={52} round />
            </WhatsappShareButton>
             <p style={{textAlign:"center",marginTop:4}}>Whatsapp</p>
            </div>

            <div style={{marginLeft:WindowWith*0.04,justifyContent:"center"}}>
            <FacebookShareButton
             url={shareLink}
            quote={shareLink}
             hashtag={shareLink}
             style={{marginLeft:WindowWith * 0.02}}
             onClick={()=>sharePost()}
            >
           <FacebookIcon size={52} round />
            </FacebookShareButton>
             <p style={{textAlign:"center",marginTop:4}}>Facebook</p>
            </div>

           
            <div style={{marginLeft:WindowWith*0.04,justifyContent:"center"}}>
            <LinkedinShareButton
            url={shareLink}
            quote={shareLink}
             hashtag={shareLink}
             style={{marginLeft:WindowWith * 0.02}}
             onClick={()=>sharePost()}
            >
           <LinkedinIcon size={52} round />
            </LinkedinShareButton>
             <p style={{textAlign:"center",marginTop:4}}>Linkedin</p>
            </div>
            </div>

             <p onClick={()=>dispatch(setShareModal(false))} style={{borderStyle:"solid",borderWidth:1,padding:10,borderRadius:25,height:25,marginTop:20,marginLeft:WindowWith*0.08,textAlign:"center",width:WindowWith*0.8}}>Cancel</p>
    
            </div>
        
          </Drawer>
        </React.Fragment>
  );

}

export default ShareModal;
