import React,{useRef} from "react"
import "./navbar.css";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch } from 'react-redux';
import { setSideBarState } from '../../Redux/Reducers/appState';
import { useNavigate } from 'react-router-dom';

const menuOption = 0



const BrokersAppBar = ({menuOption = "Home"}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const WindowWith = windowSize.current[0]
    
  
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
  return (
    <Box   sx={{ flexGrow: 1 ,}}>
      <AppBar position='fixed' sx={{ backgroundColor:"#0b1118"}}  color="inherit">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 0,color:"white" }}
            onClick={()=>dispatch(setSideBarState(true))}
          >
            <MenuIcon />
          </IconButton>
  


         <div style={{display:"flex",flexDirection:"row"}}>

         {
          menuOption==="Home"?
          <div style={{marginTop:30}}>
          <Button      
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={()=>navigate("/home")} 
            style={{textTransform: 'none', marginRight:-3,color:"white",marginTop:-10,fontSize:13}} 
            color="inherit">
           Home
          </Button>
          <p style={{color:"white",marginTop:-15,textAlign:"center"}}>__</p>
          </div>
          :<div style={{marginTop:30}}>
          <Button      
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={()=>navigate("/home")} 
            style={{textTransform: 'none', marginRight:-3,color:"white",marginTop:-10,fontSize:13}} 
            color="inherit">
           Home
          </Button>

          </div>
          }
      
          {
          menuOption==="Tech"?
          <div style={{marginTop:30}}>
          <Button      
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={()=>navigate("/tech")} 
            style={{textTransform: 'none', marginRight:-3,color:"white",marginTop:-10,fontSize:13}} 
            color="inherit">
            Tech
          </Button>
          <p style={{color:"white",marginTop:-15,textAlign:"center"}}>__</p>
          </div>
          :<div style={{marginTop:30}}>
          <Button      
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={()=>navigate("/tech")} 
            style={{textTransform: 'none', marginRight:-3,color:"white",marginTop:-10,fontSize:13}} 
            color="inherit">
            Tech
          </Button>

          </div>
          }
          {
          menuOption==="Sports"?
          <div style={{marginTop:30}}>
          <Button      
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            //onClick={()=>navigate("/home")} 
            style={{textTransform: 'none', marginRight:-3,color:"white",marginTop:-10,fontSize:13}} 
            color="inherit">
            Sports
          </Button>
          <p style={{color:"white",marginTop:-15,textAlign:"center"}}>__</p>
          </div>
          :<div style={{marginTop:30}}>
          <Button      
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={()=>navigate("/sports")} 
            style={{textTransform: 'none', marginRight:-3,color:"white",marginTop:-10,fontSize:13}} 
            color="inherit">
            Sports
          </Button>

          </div>
          }
          {
          menuOption==="Jobs"?
          <div style={{marginTop:30}}>
          <Button      
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            //onClick={()=>navigate("/home")} 
            style={{textTransform: 'none', marginRight:-3,color:"white",marginTop:-10,fontSize:13}} 
            color="inherit">
            Jobs
          </Button>
          <p style={{color:"white",marginTop:-15,textAlign:"center"}}>__</p>
          </div>
          :<div style={{marginTop:30}}>
          <Button      
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={()=>navigate("/jobs")} 
            style={{textTransform: 'none', marginRight:-3,color:"white",marginTop:-10,fontSize:13}} 
            color="inherit">
            Jobs
          </Button>

          </div>
          }
            {
          menuOption==="Crypto"?
          <div style={{marginTop:30}}>
          <Button      
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            //onClick={()=>navigate("/home")} 
            style={{textTransform: 'none', marginRight:-3,color:"white",marginTop:-10,fontSize:13}} 
            color="inherit">
            Crypto
          </Button>
          <p style={{color:"white",marginTop:-15,textAlign:"center"}}>__</p>
          </div>
          :<div style={{marginTop:30}}>
          <Button      
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={()=>navigate("/crypto")} 
            style={{textTransform: 'none', marginRight:-3,color:"white",marginTop:-10,fontSize:13}} 
            color="inherit">
            Crypto
          </Button>

          </div>
          }
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}


export default BrokersAppBar;