import BrokersAppBar from "../../components/nav-bar/navbar";
import BrokerSideBar from '../../components/sidebar/sidebar';

import News from "./News";
import { useSelector } from 'react-redux';

const  Crypto = () => {

  const appTheme = useSelector((data)=>data.appState.appTheme)

  return (
    <div style={{backgroundColor:`${appTheme}`}}>
        <BrokersAppBar menuOption="Crypto" />
        <br />
        <br />
        <div style={{marginTop:20}}>
        <br />
        <News />
        <br />
        <BrokerSideBar />
        </div>
      
    </div>
  );
}

export default Crypto;