import React,{useState,useEffect,useRef} from "react"
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import logo from "../../../assets/logo/logo.png";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import API from "../../../API/API";
import { useSelector, useDispatch } from "react-redux";
import { setSuccessMessage } from "../../../Redux/Reducers/appState";



const  ResetPassword = () => {
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const WindowWith = windowSize.current[0]
    const [showPassword, setShowPassword] = React.useState(false);
    const navigate = useNavigate();
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [password,setPassword] = useState("")
    const [confirmpassword, setConfirmPassword] = useState('Confirm password');
    const [isLoading, setLoading] = useState(false);
    const [mismatch,setMismatch] = useState(0)
    const [validation,setValidation]= useState(false)

    const [state,setState] = useState([]);
    const userEmail = useSelector((data)=>data.appState.email)
     const dispatch = useDispatch();
    const updatePassword = async () =>{

      if(password!==confirmpassword){
          setValidation(false);
          setMismatch(1)
      }else if(password === confirmpassword){
         setMismatch(0)
         setValidation(true);
      }
     
  
     if(validation===true)
    {
        setLoading(true)
      const requestOptions = {
        method: 'POST',
        Accept:'application/json',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({"email":`${userEmail}`,"password":`${password}`})
      };
      
      try {
      
        const response = await fetch(`${API.BASE_URL}/resetpassword`,requestOptions)
        const json = await response.json();
        var data = json
        setState(data);   
     
      } 
      catch (error) 
      {
        console.error(error);
      }
       finally 
       {
           setLoading(false)
          
      } 
     }
  }
  
      useEffect(()=>{
          if(state.status===201)
          {
            dispatch(setSuccessMessage("Account password updated successfully"))
            setTimeout(()=>{
                navigate("/successpage")
            },1000)
           
          }else if(state.status===401){
              setLoading(false)
          }
       
    },[state]);
  
  
  
  
  
    return (
      <div style={{flex:1,height:"800px"}}>
             <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
              <img onClick={()=>navigate("/home")}  src={logo} style={{marginTop:"55px",marginBottom:3}} height={130} width={130} alt='logo'/>
              <p  style={{marginLeft:WindowWith*0.0,marginBottom:-10,fontSize:10,color:"black"}}>Please enter a new password</p>
             </div>
   <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <div>

          <FormControl sx={{ mt:3,ml:WindowWith * 0.025, width:WindowWith * 0.6 }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              onChange={(event)=>setPassword(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
         </FormControl>
         <p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:-5,marginBottom:-5,marginLeft:100}}>{mismatch===1?<p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:10,marginBottom:-10,marginLeft:0}}>passwords mismatch</p>:""}</p>
          <FormControl sx={{ mt:3,ml:WindowWith * 0.025, width:WindowWith * 0.6}} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              onChange={(event)=>setConfirmPassword(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirrm Password"
            />
              </FormControl>
              <p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:-5,marginBottom:-5,marginLeft:100}}>{mismatch===1?<p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:10,marginBottom:-10,marginLeft:0}}>passwords mismatch</p>:""}</p>
             <LoadingButton
            size="small"
            onClick={()=>updatePassword()}
            loading={isLoading}
            loadingIndicator="Loading…"
            variant="outlined"
            sx={{ mt:3,ml:WindowWith * 0.025, width: WindowWith * 0.6 ,p:2,borderColor:"black",color:"black"}} 
          >
            <span>UPDATE</span>
          </LoadingButton>
        
        </div>
      </Box>
  
      </div>
    );
  }
  
  export default ResetPassword;