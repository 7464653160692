import React,{useState,useEffect,useRef} from "react"
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useNavigate } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import logo from "../../../assets/logo/logo.png";
import { useDispatch} from 'react-redux';
import { SetEmail } from "../../../Redux/Reducers/appState";
import API from "../../../API/API";

const  ForgotPassword = () => {

   
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const WindowWith = windowSize.current[0]
  const navigate = useNavigate();
  const dispatch = useDispatch()
  
  const [email,setEmail] = useState("")
  const [isLoading, setLoading] = useState(false);
  const [validation,setValidation]= useState(false)
  const [emailExisted,setEmailExisted] = useState(0)
  const [invalidEmail,setInvalideEmail] = useState(0)
  const [state,setState] = useState([]);
  const resetUserPassword = async () =>
  {
 
    if(email===""){
        setValidation(false);
        setInvalideEmail(1);
    }else {
        setInvalideEmail(0);
        setValidation(true);
    }

   

   if(validation===true)
  {
      setLoading(true)
    const requestOptions = {
      method: 'POST',
      Accept:'application/json',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({"email":`${email}`})
    };
    
    try {
    
      const response = await fetch(`${API.BASE_URL}/forgotpassword`,requestOptions)
      const json = await response.json();
      var data = json
      setState(data);   
   
    } 
    catch (error) 
    {
      console.error(error);
    }
     finally 
     {
         setLoading(false)
        
    } 
   }
}

    useEffect(()=>{
        if(state.status===201){
              dispatch(SetEmail(state.email))
               setTimeout(()=>{
                navigate("/otp/page")
               },1000)
              

          
            }else if(state.status===401){//Incorrect password
              setLoading(false)
              setEmailExisted(1)
            
            }
        
  },[state]);





  return (
    <div style={{flex:1,height:"800px"}}>
           <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
              <img onClick={()=>navigate("/home")}  src={logo} style={{marginTop:"55px",marginBottom:3}} height={130} width={130} alt='logo'/>
              <p  style={{textAlign:"center",marginBottom:-10,fontSize:13,color:"black"}}>RESET PASSWORD</p>
         </div>
     <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <div>
      
        <TextField
          label="enter your email................."
          id="outlined-start-adornment"
          sx={{ mt:3,ml:WindowWith * 0.025, width:WindowWith * 0.6 }}
          onChange={(event)=>setEmail(event.target.value)}
          required={true}
        />
      
      <p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:-5,marginBottom:-5,marginLeft:100}}>{invalidEmail===1?<p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:10,marginBottom:-10,marginLeft:0}}>Please enter a valid email</p>:""}</p>
      <p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:-5,marginBottom:-5,marginLeft:100}}>{emailExisted===1?<p style={{color:"red",fontWeight:"bold",fontSize:10,marginTop:10,marginBottom:-10,marginLeft:-(WindowWith*0.2)}}>{state.message}</p>:""}</p>
       

           <LoadingButton
          size="small"
          onClick={()=>resetUserPassword()}
          loading={isLoading}
          loadingIndicator="Loading…"
          variant="outlined"
          sx={{ mt:3,ml:WindowWith * 0.025, width: WindowWith * 0.6 ,p:2,borderColor:"black",color:"black"}} 
        >
          <span>RESET</span>
        </LoadingButton>

      </div>
    </Box>

    </div>
  );
}

export default ForgotPassword;