import React,{useState,useEffect,useRef} from "react"
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import logo from "../../../assets/logo/logo.png";
import { useDispatch,useSelector} from 'react-redux';
import { setFirstName,setLastName,setID,setIsLoggedIn,setIsLoggedOut,SetEmail,SetAdmin,setToken } from "../../../Redux/Reducers/appState";
import API from "../../../API/API";

const  Login = () => {

  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const WindowWith = windowSize.current[0]
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const isLoggedOut = useSelector((data)=>data.appState.isLoggedOut)
  const dispatch = useDispatch()
  
  const [email,setEmail] = useState("")
  const [password,setPassword] = useState("")
  const [isLoading, setLoading] = useState(false);
  const [mismatch,setMismatch] = useState(0)
  const [validation,setValidation]= useState(false)
  const [emailExisted,setEmailExisted] = useState(0)
  const [invalidEmail,setInvalideEmail] = useState(0)
  const [state,setState] = useState([]);
  const LoginUser = async () =>
  {
 
    if(email===""){
        setValidation(false);
        setInvalideEmail(1);
    }else {
        setInvalideEmail(0);
        setValidation(true);
    }

   

   if(validation===true)
  {
      setLoading(true)
    const requestOptions = {
      method: 'POST',
      Accept:'application/json',
      headers: {
             'Content-Type': 'application/json',
           
             
         },
      body: JSON.stringify({"email":`${email}`,"password":`${password}`})
    };
    
    try {
    
      const response = await fetch(`${API.BASE_URL}/login`,requestOptions)
      const json = await response.json();
      var data = json
      setState(data);   
   
    } 
    catch (error) 
    {
      console.error(error);
    }
     finally 
     {
         setLoading(false)
        
    } 
   }
}

    useEffect(()=>{
        if(state.status===202){//Login successfully
            if(isLoggedOut===true)
            {
              const {id,email,firstName,lastName,isAdmin} = state.user
             dispatch(setToken(state.token))
             dispatch(setIsLoggedIn(true))
             dispatch(SetEmail(email))
             dispatch(setFirstName(firstName))
             dispatch(setLastName(lastName))
             dispatch(SetAdmin(isAdmin))
             dispatch(setIsLoggedOut(false)) //Prevent double login
             dispatch(setID(id))
             setTimeout(()=>{
                navigate("/home")
            },1000)
            }
            
            }else if(state.status===401){//Incorrect password
              setLoading(false)
              setMismatch(1)
              
            }else if(state.status===403){//Incorrect password
              setLoading(false)
              setMismatch(1)
              
            }else if(state.status===404){//Invalid email 
                   setEmailExisted(0);
                   setInvalideEmail(1);
                   setLoading(false)
        
            }
  },[state]);





  return (
    <div style={{flex:1,height:"800px"}}>
         <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
              <img onClick={()=>navigate("/home")}  src={logo} style={{marginTop:"55px",marginBottom:3}} height={130} width={130} alt='logo'/>
         </div>
 <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <div>
     
        <TextField
          label="Email"
          id="outlined-start-adornment"
          sx={{ mt:3,ml:WindowWith * 0.025, width:WindowWith * 0.6 }}
          onChange={(event)=>setEmail(event.target.value)}
          required={true}
        />
      
      <p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:-5,marginBottom:-5,marginLeft:100}}>{invalidEmail===1?<p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:10,marginBottom:-10,marginLeft:0}}>Please enter a valid email</p>:""}</p>
      <p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:-5,marginBottom:-5,marginLeft:100}}>{emailExisted===1?<p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:10,marginBottom:-10,marginLeft:0}}>{state.message}</p>:""}</p>
        <FormControl sx={{ mt:3,ml:WindowWith * 0.025, width:WindowWith * 0.6 }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            onChange={(event)=>setPassword(event.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
          </FormControl>
            <p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:-5,marginBottom:-5,marginLeft:100}}>{mismatch===1?<p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:10,marginBottom:-10,marginLeft:0}}>{state.message}</p>:""}</p>
           <LoadingButton
          size="small"
          onClick={()=>LoginUser()}
          loading={isLoading}
          loadingIndicator="Loading…"
          variant="outlined"
          sx={{ mt:3,ml:WindowWith * 0.025, width: WindowWith * 0.6 ,p:2,borderColor:"black",color:"black"}} 
        >
          <span>Login</span>
        </LoadingButton>
        <p onClick={()=>navigate("/forgotpassword")} style={{marginLeft:WindowWith * 0.57,fontSize:11,color:"red"}}>forgot password?</p>
      </div>
    </Box>

    </div>
  );
}

export default Login;