import React,{useRef} from "react"
import logo from "../../assets/logo/logo.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


const SuccessPage =()=>{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const WindowWith = windowSize.current[0]
    const navigate = useNavigate();
    const successMessage = useSelector((data)=>data.appState.successMessage)
    setTimeout(()=>{
        navigate("/home")
    },10000)

    return(      
         <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
          <img onClick={()=>navigate("/home")}  src={logo} style={{marginTop:"55px",marginBottom:3}} height={130} width={130} alt='logo'/>
          <p  style={{marginLeft:WindowWith*0.0,marginBottom:-10,fontSize:10,color:"black"}}>{successMessage}</p>
        </div>
        )

}

export default SuccessPage;