import React,{useState,useEffect,useRef} from "react"
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import InsertCommentOutlinedIcon from '@mui/icons-material/InsertCommentOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import TurnedInNotOutlinedIcon from '@mui/icons-material/TurnedInOutlined';
import { useSelector,useDispatch } from 'react-redux';
import { Divider } from "../../../components/Controllers/NumberFormater";
import ShareIcon from '@mui/icons-material/Share';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';
import ReportOffIcon from '@mui/icons-material/ReportOff';
import API from "../../../API/API";
import { setRoutePage,setSuccessMessage } from "../../../Redux/Reducers/appState";
import CircularProgress from '@mui/material/CircularProgress';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const NCCard =({heading,body,image,date,likes,views,comments,bookmarks,share,data})=> {
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const appTheme = useSelector((data)=>data.appState.appTheme)
  const userID = useSelector((data)=>data.appState.userID)
  const token = useSelector((data)=>data.appState.token)
  const appTextColor = useSelector((data)=>data.appState.appTextColor)
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const WindowWith = windowSize.current[0]
  const dispatch = useDispatch();
  const [isLoading,setLoad] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const deleteNews = async () =>
  {
   
    const requestOptions = {
      method: 'POST',
      Accept:'application/json',
      headers: { 
          'Content-Type': 'application/json',
          'Authorization':token,
       },
      body: JSON.stringify({"userID":`${userID}`,'newsID':`${data.id}`})
    };
   setLoad(true)
    try {
      const response = await fetch(`${API.BASE_URL}/admin/deletenews`,requestOptions)
      const res = await response.json();
      if(res.status===1){
        dispatch(setSuccessMessage(res.message))
        dispatch(setRoutePage("/PIDControllerX/adminnews"))
        navigate("/actionsuccesspage")
      }else{
        dispatch(setSuccessMessage(res.message))
        dispatch(setRoutePage("/PIDControllerX/adminnews"))
        navigate("/actionsuccesspage")
      }

    } 
    catch (error) 
    {
      alert('Error' + error)
    }
     finally 
     {
      
        
    } 
   }

  

  return (
    <Card sx={{ maxWidth: 345 ,backgroundColor:`${appTheme}`, margin:2,marginLeft:WindowWith * 0.01}}>
      <div style={{display:"flex",flexDirection:"row",margin:12}}>
      <img src={image}  style={{height:WindowWith*0.2,width:WindowWith*0.2}} />
       <p style={{color:`${appTextColor}`,marginLeft:WindowWith * 0.03,marginTop:2,fontWeight:"500",fontSize:10}}>{heading}</p>
     </div>
       <p style={{color:`${appTextColor}`,marginLeft:WindowWith * 0.03,marginTop:-20,fontWeight:"200",fontSize:10,marginLeft:WindowWith * 0.43}}>{date}</p>
     
   
      <CardActions disableSpacing>
      <IconButton sx={{marginLeft:1}}  aria-label="add to favorites">
          <InsertCommentOutlinedIcon sx={{color:`${appTextColor}`}}  />
          <p style={{marginLeft:5,fontSize:13,color:`${appTextColor}`}}>{Divider(comments)}</p>
      </IconButton>
        <IconButton  sx={{marginLeft:1}}  aria-label="add to favorites">
          <FavoriteBorderIcon  sx={{color:`${appTextColor}`}}  />
          <p style={{marginLeft:5,fontSize:13,color:`${appTextColor}`}}>{Divider(likes)}</p>
        </IconButton>
        <IconButton sx={{marginLeft:1}} aria-label="add to favorites">
          <VisibilityOutlinedIcon  sx={{color:`${appTextColor}`}}  />
          <p style={{marginLeft:5,fontSize:13,color:`${appTextColor}`}}>{Divider(views)}</p>
        </IconButton>

        <IconButton  sx={{marginLeft:1}}  aria-label="add to favorites">
          <TurnedInNotOutlinedIcon  sx={{color:`${appTextColor}`}}  />
          <p style={{marginLeft:5,fontSize:13,color:`${appTextColor}`}}>{Divider(bookmarks)}</p>
        </IconButton>

        <IconButton  sx={{marginLeft:1}}  aria-label="add to favorites">
         <ShareIcon  sx={{color:`${appTextColor}`}}  />
          <p style={{marginLeft:5,fontSize:13,color:`${appTextColor}`}}>{Divider(share)}</p>
        </IconButton>
      </CardActions>
      <div style={{display:'flex',flexDirection:'row',}}>
      <div  style={{display:'flex',marginBottom:12,marginLeft:WindowWith * 0.05,borderStyle:'solid',width:WindowWith * 0.18,borderWidth:1.5,alignItems:'center',justifyContent:'center',height:WindowWith * 0.065,borderRadius:'3px'}} >
       <p style={{color:'black',marginRight:'5px',fontSize:13}}>Edit</p> 
       <EditNoteIcon sx={{fontSize:16}}  />
      </div>
      <div  style={{display:'flex',marginBottom:12,marginLeft:WindowWith * 0.05,borderStyle:'solid',width:WindowWith * 0.2,borderWidth:1.5,alignItems:'center',justifyContent:'center',height:WindowWith * 0.065,borderRadius:'3px',borderColor:'#F67F00'}} >
       <p style={{color:'#F67F00',marginRight:'5px',fontSize:13}}>Suspend</p> 
       <ReportOffIcon  sx={{color:'#F67F00',fontSize:16}}  />
      </div>
      {isLoading?<CircularProgress  sx={{fontSize:5,marginLeft:5,color:"red",marginTop:-1}} />:
      <div onClick={()=>deleteNews()} style={{display:'flex',marginBottom:12,marginLeft:WindowWith * 0.05,borderStyle:'solid',width:WindowWith * 0.22,borderWidth:1.5,alignItems:'center',justifyContent:'center',height:WindowWith * 0.065,borderRadius:'3px',borderColor:'#FF3136'}} >
       <p style={{color:'#FF3136',marginRight:'5px',fontSize:13}}>Delete</p> 
       <DeleteIcon  sx={{color:'#FF3136',fontSize:16}} />
      </div>}
      </div>
   
    </Card>
  );
}

export default NCCard;