import React,{useState,useEffect} from "react";
import NewsSkeleton from "../Home/Skeleton";
import { NewsCard } from "../Card/Card";
import API from "../../API/API";
import { useDispatch,useSelector } from "react-redux";
import { setVisitorId } from "../../Redux/Reducers/appState";
import ShareModal from "../Home/ShareModal/ShareModal";
import axios from 'axios'

const News =()=>{

    const [isLoading, setLoading] = useState(true);
    const [news,setNews] = useState({});
    const visitorId = useSelector((data)=>data.appState.visitorId)
    const dispatch = useDispatch()
    const appTheme = useSelector((data)=>data.appState.appTheme)



 
   
    const FetchProducts = async () =>
    {
      const res = await axios.get('https://ipapi.co/json/')
      const requestOptions = {
        method: 'POST',
        Accept:'application/json',
        headers: { 
            'Content-Type': 'application/json',
            'ip':res.data.ip,
            'city':res.data.city,
            'neworkIP':res.data.network,
            'longitude':res.data.longitude,
            'latitude':res.data.latitude,
            'country_name':res.data.country_name,
            'visitorid':visitorId,
            'categoryId':'5'
         },
        body: JSON.stringify({"visitorId":`${visitorId}`})
      };
      
      try {
      
        const response = await fetch(`${API.BASE_URL}/readnews`,requestOptions)
        const json = await response.json();
        var data = json
        setNews(data);   
     
      } 
      catch (error) 
      {
        console.error(error);
      }
       finally 
       {
           setLoading(false)
          
      } 
     }
  
      useEffect(() =>{
      FetchProducts()
      
    },[]);

    const NewsList = ({data})=>{

         dispatch(setVisitorId(news.visitorID))
       
        return (
            <div style={{display:"flex",flex:1,flexDirection:"column",justifyContent:"center",alignItems:"center",}}>
            {
                data.map((data)=><NewsCard  heading = {data.heading} image={data.image} body={data.body} comments={data.comments} likes={data.likes} views={data.views} bookmarks={data.bookmarks} share={data.share} date={data.date}  data={data} />)

            }
            </div>
        )
    }
  
    return (
      <div style={{backgroundColor:`${appTheme}`}} >
          {isLoading?<NewsSkeleton isLoading={isLoading} />:<NewsList  data = {news.data} />}
          <ShareModal />
      </div>
    );
  }

  export default News;