import React,{useState,useEffect,useRef} from "react"
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import LoadingButton from '@mui/lab/LoadingButton';
import logo from "../../../assets/logo/logo.png";
import API from "../../../API/API";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useDispatch,useSelector } from "react-redux";
import { setSuccessMessage } from "../../../Redux/Reducers/appState";
import './style.css'
import {data} from './categoryData'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const  CreateNews = () => {
  
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const WindowWith = windowSize.current[0]
  const navigate = useNavigate();
  const [heading,setHeading] = useState("")
  const [image,setImage] = useState("T")
  const [body,setBody] = useState("")
  const userID = useSelector((data)=>data.appState.userID)
  const token = useSelector((data)=>data.appState.token)
  const [isLoading, setLoading] = useState(false);
  const [validation,setValidation]= useState(false)
  const [invalidheading,setInvalidHeading] = useState(0)
  const [invalidBody,setInvalideBody] = useState(0)
  const [invalidImage,setInvalideImage] = useState(0)
  const [invalidJobLink,setInvalideJobLink] = useState(0)
  const [state,setState] = useState([]);
  const  [p1,setP1] = useState("");
  const  [p2,setP2] = useState("");
  const  [p3,setP3] = useState("");
  const  [p4,setP4] = useState("");
  const  [p5,setP5] = useState("");
  const  [jobLink,setJobLink] = useState("");
  const  [p6,setP6] = useState("");
  const  [p7,setP7] = useState("");
  const  [p8,setP8] = useState("");
  const  [p9,setP9] = useState("");
  const  [p10,setP10] = useState("");
  const  [p11,setP11] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [file,setFile] = useState(null)
  const [categoryDropDown,setCategoryDropDown] = useState(false)
  const [categoryChoice,setCategoryChoice] = useState([])
  
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setFile(URL.createObjectURL(event.target.files[0]));
  };

  const dispatch = useDispatch();

  const  [IC1,setIC1] = useState(0);//IC = image controller

  const [paragraphCounter,setParagraphCounter] =  useState(0);
   
  const  Card = ({data}) =>{
    return (
      <div onClick={()=>{setCategoryChoice(data);setCategoryDropDown(false)}} style={{display:"flex",flexDirection:'row',alignItems:'center',justifyContent:'center',borderStyle:'solid',borderWidth:'1px',borderColor:'#E4E4E4',width:WindowWith * 0.6,marginLeft:WindowWith * 0.19,marginTop:WindowWith * 0.00,borderRadius:3}}>
         <p>{data.name}</p>
       </div>
    )

  }


  const CreateNews = async () =>
  {
    if(heading===""){
        setValidation(false);
        setInvalidHeading(1);
    }else {
        setInvalidHeading(0);
       setValidation(true);
    }
    if(selectedFile===null){
        setValidation(false);
        setInvalideImage(1);
    } else {
       setInvalideImage(0);
       setValidation(true);
    }
    if(body===""){
        setValidation(false);
        setInvalideBody(1);
    }else {
        setInvalideBody(0);
        setValidation(true);
    }
    if((jobLink==="")&&(categoryChoice.id===4)){
      setValidation(false);
      setInvalideJobLink(1);
  }else {
      setInvalideJobLink(0);
      setValidation(true);
  }


    

 

   if(validation===true)
  {
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('heading',heading);
    formData.append('image',image);
    formData.append('creatorid',userID);
    formData.append('body',body);
    formData.append('p1',p1);
    formData.append('p2',p2);
    formData.append('p3',p3);
    formData.append('p4',p4);
    formData.append('p5',p5);
    formData.append('redirect',jobLink);
    formData.append('categoryid',categoryChoice.id);


      setLoading(true)
     const requestOptions = {
      method: 'POST',
     /* Accept:'application/json',
      headers: { 
                 'Content-Type': 'application/json',
                 'Authorization':token,
                 'file':formData
                },*/
      headers:{'Authorization':token},
      body:formData//JSON.stringify({"heading":`${heading}`,"image":`${image}`,'creatorsid':userID,"body":`${body}`,"p1":`${p1}`,"p2":`${p2}`,"p3":`${p3}`,"p4":`${p4}`,"p5":`${p5}`})
    };
    
    try {
      //createnews
      const response = await fetch(`${API.BASE_URL}/createfile`,requestOptions)
     // const json = await response.json();
     // var data = json
     // setState(data);  
     setLoading(false)
     setTimeout(()=>{
      dispatch(setSuccessMessage("Content Created Successfully"))
      navigate("/successpage")
     },1000) 

   
    } 
    catch (error) 
    {
      console.error(error);
    }
     finally 
     {
        setLoading(false)
      
        
    } 
   }
}

    useEffect(()=>{
        if(state.status===1)
        {
         
       
        }else if(state.status===404){
         
            
        }
     
  },[state]);





  return (
    <div style={{flex:1,height:"800px"}}>
        <Header />
         <div>
              <img src={logo} style={{marginTop:"55px",marginLeft:WindowWith * 0.33,marginBottom:3}} height={130} width={WindowWith * 0.34} alt='logo'/>
         </div>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <div>
           <div onClick={()=>setCategoryDropDown(~categoryDropDown)} style={{display:"flex",flexDirection:'row',alignItems:'center',justifyContent:'space-between',borderStyle:'solid',borderWidth:'1px',borderColor:'#E4E4E4',width:WindowWith * 0.6,marginLeft:WindowWith * 0.19,marginTop:WindowWith * 0.04,borderRadius:3,}}>
              {categoryChoice.length===0?<p style={{paddingLeft:WindowWith * 0.12}}>Select Category</p>:<p style={{paddingLeft:WindowWith * 0.25}}>{categoryChoice.name}</p>}
               {categoryDropDown?<KeyboardArrowDownIcon sx={{fontSize:30,marginLeft:WindowWith * 0.01}} />:<KeyboardArrowRightIcon sx={{fontSize:30,marginLeft:WindowWith * 0.01}} />}
           </div>
             {categoryDropDown?data.map((data)=><Card  data={data}/>):''}
        <TextField
          label="News Headline"
          id="outlined-start-adornment"
          sx={{ mt:3,ml:WindowWith * 0.025, width:WindowWith * 0.6 }}
          onChange={(event)=>setHeading(event.target.value)}
          required={true}
        />
         <p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:-5,marginBottom:-5,marginLeft:100}}>{invalidheading===1?<p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:10,marginBottom:-10,marginLeft:0}}>Please enter a heading</p>:""}</p>
         <div style={{display:'flex',flexDirection:'column',justifyContent:"center",alignItems:'center',marginLeft:WindowWith * 0.12}}>
          {file===null?<p></p>:
        <img src={file} style={{marginTop:WindowWith * 0.08}} width={ WindowWith * 0.6 } height={WindowWith * 0.6} />}
        <input className="image" type="file" onChange={(e)=>handleFileChange(e)} />
        </div>
        <p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:-5,marginBottom:-5,marginLeft:100}}>{invalidImage===1?<p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:10,marginBottom:-10,marginLeft:0}}>Please enter an image for the post </p>:""}</p>

       <div style={{display:"flex"}}>
        <TextField
          label="Intro Paragraph"
          id="outlined-start-adornment"
          sx={{ mt:3,ml:WindowWith * 0.025, width:WindowWith * 0.6 }}
          onChange={(event)=>setBody(event.target.value)}
          required={true}
          multiline
          maxRows={14}
        />
        {paragraphCounter===0?<AddCircleIcon  onClick={()=>setParagraphCounter(paragraphCounter+1)}   sx={{marginTop:5,marginLeft:1}}/>:<span></span>}
       </div>
       {
        paragraphCounter>=1? <div style={{display:"flex",flexDirection:"row"}}>
        <div>
        <TextField
          label="First Paragraph"
          id="outlined-start-adornment"
          sx={{ mt:3,ml:WindowWith * 0.025, width:WindowWith * 0.6 }}
          onChange={(event)=>setP1(event.target.value)}
          required={true}
          multiline
          maxRows={14}
        />
         </div>
        {paragraphCounter===1?<AddCircleIcon  onClick={()=>setParagraphCounter(paragraphCounter+1)}  sx={{marginTop:5,marginLeft:1}}/>:<span></span>}
       </div>:<div></div>
       }

       {
        paragraphCounter>=2? <div style={{display:"flex"}}>
        <TextField
          label="Second Paragraph"
          id="outlined-start-adornment"
          sx={{ mt:3,ml:WindowWith * 0.025, width:WindowWith * 0.6 }}
          onChange={(event)=>setP2(event.target.value)}
          required={true}
          multiline
          maxRows={14}
        />
        {paragraphCounter===2?<AddCircleIcon  onClick={()=>setParagraphCounter(paragraphCounter+1)}  sx={{marginTop:5,marginLeft:1}}/>:<span></span>}
       </div>:<div></div>
       }

       {
        paragraphCounter>=3? <div style={{display:"flex"}}>
        <TextField
          label="Third Paragraph"
          id="outlined-start-adornment"
          sx={{ mt:3,ml:WindowWith * 0.025, width:WindowWith * 0.6 }}
          onChange={(event)=>setP3(event.target.value)}
          required={true}
          multiline
          maxRows={14}
        />
        {paragraphCounter===3?<AddCircleIcon  onClick={()=>setParagraphCounter(paragraphCounter+1)}  sx={{marginTop:5,marginLeft:1}}/>:<span></span>}
       </div>:<div></div>
       }
        {
        paragraphCounter>=4? <div style={{display:"flex"}}>
        <TextField
          label="Forth Paragraph"
          id="outlined-start-adornment"
          sx={{ mt:3,ml:WindowWith * 0.025, width:WindowWith * 0.6 }}
          onChange={(event)=>setP4(event.target.value)}
          required={true}
          multiline
          maxRows={14}
        />
        {paragraphCounter===4?<AddCircleIcon  onClick={()=>setParagraphCounter(paragraphCounter+1)}  sx={{marginTop:5,marginLeft:1}}/>:<span></span>}
       </div>:<div></div>
       }
        {
        paragraphCounter>=5? <div style={{display:"flex"}}>
        <TextField
          label="Fifth Paragraph"
          id="outlined-start-adornment"
          sx={{ mt:3,ml:WindowWith * 0.025, width:WindowWith * 0.6 }}
          onChange={(event)=>setP5(event.target.value)}
          required={true}
          multiline
          maxRows={14}
        />
        {paragraphCounter===5?<AddCircleIcon  onClick={()=>setParagraphCounter(paragraphCounter+1)}  sx={{marginTop:5,marginLeft:1}}/>:<span></span>}
       </div>:<div></div>
       }
      {categoryChoice.id===4?<>
      <TextField
          label="Job site link"
          id="outlined-start-adornment"
          sx={{ mt:3,ml:WindowWith * 0.025, width:WindowWith * 0.6 }}
          onChange={(event)=>setJobLink(event.target.value)}
          required={true}
          multiline
          maxRows={14}
        /> <p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:-5,marginBottom:-5,marginLeft:100}}>{invalidJobLink===1?<p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:10,marginBottom:-10,marginLeft:0}}>Please enter a link for the job post </p>:""}</p>
        </>
        :
        <></>
      }
       
      <p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:-5,marginBottom:-5,marginLeft:100}}>{invalidBody===1?<p style={{color:"red",fontWeight:"bold",fontSize:12,marginTop:10,marginBottom:-10,marginLeft:0}}>Please enter a content</p>:""}</p>
           <LoadingButton
          size="small"
          onClick={()=>CreateNews()}
          loading={isLoading}
          loadingIndicator="Loading…"
          variant="outlined"
          sx={{ mt:3,ml:WindowWith * 0.025, width: WindowWith * 0.6 ,p:2,borderColor:"black",color:"black"}} 
        >
          <span>Create News</span>
        </LoadingButton>
      
      </div>
    </Box>

    </div>
  );
}

export default CreateNews;