import React,{useState,useEffect,useRef} from "react"
import Card from '@mui/material/Card';
import PublicIcon from '@mui/icons-material/Public';
import { useNavigate } from "react-router-dom";
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import { useSelector,useDispatch } from 'react-redux';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';
import ReportOffIcon from '@mui/icons-material/ReportOff';
import API from "../../../API/API";
import { setRoutePage,setSuccessMessage } from "../../../Redux/Reducers/appState";
import CircularProgress from '@mui/material/CircularProgress';


const VCard =({IP,LAT,LOG,date,country,city,data})=> {

  const navigate = useNavigate();
  const appTheme = useSelector((data)=>data.appState.appTheme)
  const userID = useSelector((data)=>data.appState.userID)
  const token = useSelector((data)=>data.appState.token)
  const appTextColor = useSelector((data)=>data.appState.appTextColor)
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const WindowWith = windowSize.current[0]
  const dispatch = useDispatch();
  const [isLoading,setLoad] = useState(false)


  const deleteVisitor = async () =>
  {
   
    const requestOptions = {
      method: 'POST',
      Accept:'application/json',
      headers: { 
          'Content-Type': 'application/json',
          'Authorization':token,
       },
      body: JSON.stringify({"userID":`${userID}`,'visitorID':`${data.id}`})
    };
   setLoad(true)
    try {
      const response = await fetch(`${API.BASE_URL}/adminpanel/deletevisitors`,requestOptions)
      const res = await response.json();
      if(res.status===1){
        dispatch(setSuccessMessage(res.message))
        dispatch(setRoutePage("/PIDControllerX/visitorcontroller"))
        navigate("/actionsuccesspage")
      }else{
        dispatch(setSuccessMessage(res.message))
        dispatch(setRoutePage("/PIDControllerX/visitorcontroller"))
        navigate("/actionsuccesspage")
      }

    } 
    catch (error) 
    {
      alert('Error' + error)
    }
     finally 
     {
      
        
    } 
   }

  

  return (
    <Card sx={{ maxWidth: 345 ,backgroundColor:`${appTheme}`, margin:2,marginLeft:WindowWith * 0.01}}>
      <div style={{display:"flex",flexDirection:"row",margin:12}}>
        <ImportantDevicesIcon />
       <div>
        <p style={{color:`${appTextColor}`,marginLeft:WindowWith * 0.03,marginTop:2,fontWeight:"500",fontSize:10}}>IP Address:{IP}</p>
        <p style={{color:`${appTextColor}`,marginLeft:WindowWith * 0.03,marginTop:2,fontWeight:"500",fontSize:10}}>longitude:{LOG}</p>
        <p style={{color:`${appTextColor}`,marginLeft:WindowWith * 0.03,marginTop:2,fontWeight:"500",fontSize:10}}>latitude:{LAT}</p>
        </div>
       </div>
       <div style={{display:"flex",flexDirection:"row",margin:12}}>
        <PublicIcon />
        <div>
        <p style={{color:`${appTextColor}`,marginLeft:WindowWith * 0.03,marginTop:2,fontWeight:"500",fontSize:10}}>Country:{country}</p>
        <p style={{color:`${appTextColor}`,marginLeft:WindowWith * 0.03,marginTop:2,fontWeight:"500",fontSize:10}}>City:{city}</p>
        </div>
       </div>
       <p style={{color:`${appTextColor}`,marginLeft:WindowWith * 0.03,marginTop:-20,fontWeight:"200",fontSize:10,marginLeft:WindowWith * 0.43}}>{date}</p>
     
   

      <div style={{display:'flex',flexDirection:'row',}}>
      <div  style={{display:'flex',marginBottom:12,marginLeft:WindowWith * 0.05,borderStyle:'solid',width:WindowWith * 0.18,borderWidth:1.5,alignItems:'center',justifyContent:'center',height:WindowWith * 0.065,borderRadius:'3px'}} >
       <p style={{color:'black',marginRight:'5px',fontSize:13}}>Blacklist</p> 
       <EditNoteIcon sx={{fontSize:16}}  />
      </div>
      <div  style={{display:'flex',marginBottom:12,marginLeft:WindowWith * 0.05,borderStyle:'solid',width:WindowWith * 0.2,borderWidth:1.5,alignItems:'center',justifyContent:'center',height:WindowWith * 0.065,borderRadius:'3px',borderColor:'#F67F00'}} >
       <p style={{color:'#F67F00',marginRight:'5px',fontSize:13}}>Block</p> 
       <ReportOffIcon  sx={{color:'#F67F00',fontSize:16}}  />
      </div>
      {isLoading?<CircularProgress  sx={{fontSize:5,marginLeft:5,color:"red",marginTop:-1}} />:
      <div onClick={()=>deleteVisitor()}  style={{display:'flex',marginBottom:12,marginLeft:WindowWith * 0.05,borderStyle:'solid',width:WindowWith * 0.22,borderWidth:1.5,alignItems:'center',justifyContent:'center',height:WindowWith * 0.065,borderRadius:'3px',borderColor:'#FF3136'}} >
       <p style={{color:'#FF3136',marginRight:'5px',fontSize:13}}>Delete</p> 
       <DeleteIcon  sx={{color:'#FF3136',fontSize:16}} />
      </div>}
      </div>
   
    </Card>
  );
}

export default VCard;