import React,{useState,useEffect,useRef} from "react"
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import WidgetsIcon from '@mui/icons-material/Widgets';//Admin Panel settings
import { useSelector } from 'react-redux';
import logo from "../../assets/logo/logob.png";
import { useNavigate } from "react-router-dom";


function Header() {
    const firstName = useSelector((data)=>data.appState.firstName)
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const WindowWith = windowSize.current[0]
    const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1 }}>
    <AppBar position="static" sx={{backgroundColor:"black"}}  >
      <Toolbar sx={{backgroundColor:"secondary"}}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <img onClick={()=>navigate("/home")}  src={logo} style={{marginLeft:WindowWith * 0.01,}} height={30} width={WindowWith * 0.07} alt='logo'/>
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Hello {firstName}
        </Typography>
        <Button onClick={()=>navigate("/PIDControllerX")}  color="inherit">Admin panel</Button>
      </Toolbar>
    </AppBar>
  </Box>
   
  );
}
export default Header;
